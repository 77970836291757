import React, { useState, useEffect, useCallback } from "react";
import { Link, Linkedin, QrCode, X, Download } from "lucide-react";
import {
  Facebook,
  WhatsApp,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import { API_URL } from "../../env";

const ShareSlidesModal = ({ shareModal, onClose, caseBoxId }) => {
  // State for sharing options
  const [visibility, setVisibility] = useState("unlisted");
  const [selectedOption, setSelectedOption] = useState("link");
  const [password, setPassword] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [emails, setEmails] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [shareResponse, setShareResponse] = useState(null);

  // State for slides
  const [slides, setSlides] = useState([]);
  const [selectedSlides, setSelectedSlides] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoadingSlides, setIsLoadingSlides] = useState(false);
  const [currentPageSlides, setCurrentPageSlides] = useState([]);
  const SLIDES_PER_PAGE = 6;

  // Handle escape key press
  const handleKeyDown = useCallback(
    (event) => {
      if (!event || !event.key) return;
      if (event.key.toLowerCase() === "escape") {
        onClose();
      }
    },
    [onClose]
  );

  // Add and remove event listeners
  useEffect(() => {
    if (shareModal) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [shareModal, handleKeyDown]);

  // Fetch slides when caseBoxId changes or page changes
  useEffect(() => {
    if (caseBoxId) {
      fetchSlides();
    }
  }, [caseBoxId, currentPage]);

  // Fetch slides from API
  const fetchSlides = async () => {
    setIsLoadingSlides(true);
    try {
      const response = await fetch(
        `${API_URL}case_box_images/casebox/${caseBoxId}?page=${currentPage}&limit=${SLIDES_PER_PAGE}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (currentPage === 1) {
        setSlides(data.images || []);
      } else {
        setSlides((prev) => [...prev, ...(data.images || [])]);
      }

      setCurrentPageSlides(data.images || []);
      setHasMorePages((data.images || []).length === SLIDES_PER_PAGE);
    } catch (error) {
      console.error("Error fetching slides:", error);
    } finally {
      setIsLoadingSlides(false);
    }
  };

  // Navigation handlers
  const handleLoadMore = () => {
    if (hasMorePages && !isLoadingSlides) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleLoadPrevious = () => {
    if (currentPage > 1 && !isLoadingSlides) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Toggle slide selection
  const toggleSlideSelection = (slide) => {
    setSelectedSlides((prev) => {
      const isSelected = prev.some(
        (s) => s.CaseBoxImageId === slide.CaseBoxImageId
      );
      if (isSelected) {
        return prev.filter((s) => s.CaseBoxImageId !== slide.CaseBoxImageId);
      } else {
        return [...prev, slide];
      }
    });
  };

  // Handle share action
  const handleShare = async () => {
    try {
      const submissionData = {
        casebox_id: caseBoxId,
        selected_images: selectedSlides.map((slide) => slide.CaseBoxImageId),
        password: visibility === "unlisted" ? password : "",
        shared_emails:
          selectedOption === "email"
            ? emails.split(",").map((email) => email.trim())
            : [],
        expiry_date: expirationDate,
        share_type: selectedOption,
        is_public: visibility === "public",
        is_edit: true,
      };

      const response = await fetch(`${API_URL}casebox_share/share`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();
      setShareResponse(data);

      if (data.generated_url) {
        setShareLink(data.generated_url);
        handleShareLink(data.generated_url);
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  // Handle share link actions
  const handleShareLink = (url) => {
    switch (selectedOption) {
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, "_blank");
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            url
          )}`,
          "_blank"
        );
        break;
      case "link":
        navigator.clipboard.writeText(url);
        setIsLinkCopied(true);
        setTimeout(() => setIsLinkCopied(false), 2000);
        break;
      case "email":
        window.location.href = `mailto:${emails}?subject=Shared Slides&body=${encodeURIComponent(
          url
        )}`;
        break;
    }
  };

  // Copy text to clipboard
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsLinkCopied(true);
      setTimeout(() => setIsLinkCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  // Handle QR code download
  const handleDownloadQR = () => {
    if (shareResponse?.qr_code) {
      const link = document.createElement("a");
      link.href = shareResponse.qr_code;
      link.download = "slides-qr-code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!shareModal) return null;

  return (
    <div
      className="share-modal-overlay"
      onClick={(e) => {
        if (e.target.className === "share-modal-overlay") onClose();
      }}
    >
      <div className="share-modal">
        <button className="close-button" onClick={onClose}>
          ×
        </button>

        <h2 className="modal-title">Share Slides</h2>

        <div
          className="slides-selection-container"
          style={{ minHeight: "300px" }}
        >
          <h3 className="slides-section-title">
            Select Slides to Share ({selectedSlides.length} selected)
          </h3>
          <div className="slides-grid-container">
            <button
              className="slide-nav-button prev"
              onClick={handleLoadPrevious}
              disabled={currentPage === 1 || isLoadingSlides}
            >
              <NavigateBefore style={{ fontSize: 24, color: "#666" }} />
            </button>

            <div className="slides-grid">
              {isLoadingSlides ? (
                <div className="loading-indicator">Loading...</div>
              ) : (
                currentPageSlides.map((slide) => (
                  <div
                    key={slide.CaseBoxImageId}
                    className={`slide-item ${
                      selectedSlides.some(
                        (s) => s.CaseBoxImageId === slide.CaseBoxImageId
                      )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => toggleSlideSelection(slide)}
                  >
                    <div className="slide-thumbnail-container">
                      <img
                        src={slide.Thumbnail}
                        alt={slide.SlideName}
                        className="slide-thumbnail"
                      />
                    </div>
                    <div className="slide-name">{slide.SlideName}</div>
                  </div>
                ))
              )}
            </div>

            <button
              className="slide-nav-button next"
              onClick={handleLoadMore}
              disabled={!hasMorePages || isLoadingSlides}
            >
              <NavigateNext style={{ fontSize: 24, color: "#666" }} />
            </button>
          </div>
        </div>

        <div className="visibility-container">
          <div className="visibility-header">
            <span>Visibility</span>
            <span className="info-icon">ⓘ</span>
          </div>
          <div className="visibility-options">
            {/* <label className="visibility-option">
              <input
                type="radio"
                name="visibility"
                value="public"
                checked={visibility === "public"}
                onChange={(e) => setVisibility(e.target.value)}
              />
              <span className="visibility-radio"></span>
              <span className="visibility-text">Public</span>
            </label> */}
            <label className="visibility-option">
              <input
                type="radio"
                name="visibility"
                value="unlisted"
                checked={visibility === "unlisted"}
                onChange={(e) => setVisibility(e.target.value)}
              />
              <span className="visibility-radio"></span>
              <span className="visibility-text">Private</span>
            </label>
          </div>
        </div>

        <div className="share-options">
          <button
            className={`share-option ${
              selectedOption === "link" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("link")}
            data-option="link"
          >
            <span className="option-icon">
              <Link />
            </span>
            <span className="option-label">Link</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "qrcode" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("qrcode")}
            data-option="qrcode"
          >
            <span className="option-icon">
              <QrCode />
            </span>
            <span className="option-label">QR Code</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "email" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("email")}
            data-option="email"
          >
            <span className="option-icon">✉️</span>
            <span className="option-label">Email</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "facebook" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("facebook")}
            data-option="facebook"
          >
            <span className="option-icon fb-icon">
              <Facebook />
            </span>
            <span className="option-label">Facebook</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "twitter" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("twitter")}
            data-option="twitter"
          >
            <span className="option-icon">
              <X />
            </span>
            <span className="option-label">X(Twitter)</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "whatsapp" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("whatsapp")}
            data-option="whatsapp"
          >
            <span className="option-icon">
              <WhatsApp />
            </span>
            <span className="option-label">WhatsApp</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "linkedin" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("linkedin")}
            data-option="linkedin"
          >
            <span className="option-icon linkedin-icon">
              <Linkedin />
            </span>
            <span className="option-label">LinkedIn</span>
          </button>
        </div>

        {selectedOption === "email" && (
          <div className="input-group">
            <label>Email Addresses</label>
            <input
              type="text"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              placeholder="Enter emails separated by commas"
              className="input-fieldDate"
            />
          </div>
        )}

        <div className="input-row">
          <div className="input-group">
            <label>Expiration Date</label>
            <input
              type="datetime-local"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
              className="input-fieldDate"
            />
          </div>
          {visibility === "unlisted" && (
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="input-fieldDate"
              />
            </div>
          )}
        </div>

        {shareResponse?.generated_url && selectedOption === "link" && (
          <div className="share-link-container">
            <label>Share URL</label>
            <div className="share-link-wrapper">
              <input
                type="text"
                value={shareResponse.generated_url}
                readOnly
                className="share-link-input"
              />
              <button
                onClick={() => copyToClipboard(shareResponse.generated_url)}
                className="copy-button"
              >
                {isLinkCopied ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>
        )}

        {shareResponse?.qr_code && selectedOption === "qrcode" && (
          <div className="qr-code-container">
            <img
              src={shareResponse.qr_code}
              alt="QR Code"
              className="qr-code-image"
            />
            <div onClick={handleDownloadQR} className="download-qr-button">
              <Download size={10} />
              Download QR Code
            </div>
          </div>
        )}

        <div className="modal-actions">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="share-button"
            onClick={handleShare}
            disabled={selectedSlides.length === 0}
          >
            Share ({selectedSlides.length} slides)
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareSlidesModal;
