import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { AccessTime } from "@mui/icons-material";

const EnhancedQuizTimer = ({
  timeLimit,
  onTimeExpired,
  isActive = true,
  timerColor = "#2279F5",
}) => {
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60); // convert minutes to seconds
  const [isWarning, setIsWarning] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [hasExpired, setHasExpired] = useState(false); // Track if timer has already expired

  useEffect(() => {
    let interval = null;

    if (isActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = prevTime - 1;

          // Warning when less than 25% time remains
          if (newTime <= timeLimit * 60 * 0.25 && !isWarning) {
            setIsWarning(true);
          }

          // Danger when less than 10% time remains
          if (newTime <= timeLimit * 60 * 0.1 && !isDanger) {
            setIsDanger(true);
          }

          if (newTime <= 0) {
            clearInterval(interval);
            
            // Only call onTimeExpired if we haven't already called it
            if (!hasExpired && onTimeExpired) {
              setHasExpired(true);
              onTimeExpired();
            }
            return 0;
          }

          return newTime;
        });
      }, 1000);
    } else if (!isActive) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, timeLimit, timeRemaining, onTimeExpired, isWarning, isDanger, hasExpired]);

  // Reset hasExpired when the component is reactivated
  useEffect(() => {
    if (isActive && hasExpired) {
      setHasExpired(false);
    }
  }, [isActive, hasExpired]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Calculate progress percentage (reversed for countdown)
  const calculateProgress = () => {
    const totalSeconds = timeLimit * 60;
    return ((totalSeconds - timeRemaining) / totalSeconds) * 100;
  };

  // Determine color based on time remaining
  const getColor = () => {
    if (isDanger) return "#FF5252";
    if (isWarning) return "#FFC107";
    return timerColor;
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={calculateProgress()}
          size={80}
          thickness={4}
          sx={{
            color: getColor(),
            transition: "color 0.5s ease",
            filter: isDanger
              ? "drop-shadow(0 0 4px rgba(255, 82, 82, 0.7))"
              : "none",
            animation: isDanger ? "pulse 1s infinite" : "none",
            "@keyframes pulse": {
              "0%": {
                filter: "drop-shadow(0 0 2px rgba(255, 82, 82, 0.5))",
              },
              "50%": {
                filter: "drop-shadow(0 0 8px rgba(255, 82, 82, 0.8))",
              },
              "100%": {
                filter: "drop-shadow(0 0 2px rgba(255, 82, 82, 0.5))",
              },
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <AccessTime
            sx={{
              fontSize: 20,
              color: getColor(),
              mb: 0.5,
              transition: "color 0.5s ease",
            }}
          />
          <Typography
            variant="body2"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              color: getColor(),
              transition: "color 0.5s ease",
            }}
          >
            {formatTime(timeRemaining)}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="caption"
        sx={{
          mt: 1,
          color: isDanger
            ? "#FF5252"
            : isWarning
            ? "#FFC107"
            : "text.secondary",
          fontWeight: isDanger ? "bold" : "normal",
          animation: isDanger ? "blink 1s infinite" : "none",
          "@keyframes blink": {
            "0%": { opacity: 1 },
            "50%": { opacity: 0.5 },
            "100%": { opacity: 1 },
          },
        }}
      >
        {isDanger
          ? "Time almost up!"
          : isWarning
          ? "Running low!"
          : "Time Remaining"}
      </Typography>
    </Box>
  );
};
export default EnhancedQuizTimer