import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Constants
const QUESTION_TYPES = {
  multiple_choice: "Multiple Choice",
  true_false: "True/False",
  short_answer: "Short Answer",
};

const QuestionsTable = ({
  questions,
  startIndex,
  onRowClick,
  onEditQuestion,
  onDeleteQuestion,
  isStudent = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleMenuClick = (event, question) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedQuestion(question);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    onEditQuestion(selectedQuestion);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    onDeleteQuestion(selectedQuestion.question_id);
    handleMenuClose();
  };

  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.slice(0, limit)}...` : text;
  };

  if (questions.length === 0) {
    return (
      <Paper sx={{ p: 4, textAlign: "center", borderRadius: "8px" }}>
        <Typography variant="h6" color="text.secondary" fontWeight="bold">
          No Questions Found!
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          Click "Add Question" to create your first question.
        </Typography>
      </Paper>
    );
  }

  return (
    <div className="table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="table-head" style={{ paddingLeft: "16px" }}>
              Sl No
            </TableCell>
            <TableCell className="table-head">Question</TableCell>
            <TableCell className="table-head">Type</TableCell>
            <TableCell className="table-head">Points</TableCell>
            <TableCell
              style={{
                padding: "0px 16px 5px 0px",
                textAlign: "end",
                fontSize: 12,
                fontWeight: "600",
                borderBottom: "none",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((question, index) => (
            <React.Fragment key={question.question_id || index}>
              <TableRow
                className="table-row"
                onDoubleClick={() => onRowClick(question)}
              >
                <TableCell
                  style={{
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                  }}
                  className="table-cells"
                >
                  {startIndex + index + 1}
                </TableCell>
                <TableCell
                  className="table-cells"
                  style={{ overflow: "hidden" }}
                >
                  {truncateText(question.question, 10)}
                </TableCell>
                <TableCell className="table-cells">
                  <Chip
                    label={
                      QUESTION_TYPES[question.question_type] ||
                      question.question_type
                    }
                    size="small"
                    sx={{
                      bgcolor: "#E3F2FD",
                      color: "#2279F5",
                      fontSize: "0.7rem",
                      height: "24px",
                      fontWeight: 600,
                      borderRadius: "4px",
                    }}
                  />
                </TableCell>
                <TableCell className="table-cells">{question.score}</TableCell>
                <TableCell
                  style={{
                    borderTopRightRadius: 12,
                    borderBottomRightRadius: 12,
                    textAlign: "end",
                    width: 230,
                    paddingRight: "16px",
                  }}
                  className="table-cells"
                >
                  {!isStudent && (
                    <IconButton
                      size="small"
                      onClick={(e) => handleMenuClick(e, question)}
                      sx={{ marginRight: "8px" }}
                    >
                      <MoreVertIcon
                        fontSize="small"
                        style={{ color: "grey" }}
                      />
                    </IconButton>
                  )}
                  {isStudent && (
                    <Chip
                      label="Answer"
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={() => onRowClick(question)}
                      sx={{
                        fontSize: "0.7rem",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <div style={{ height: "4px" }}></div>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem dense onClick={handleEditClick}>
          Edit
        </MenuItem>
        <MenuItem dense onClick={handleDeleteClick}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default QuestionsTable;
