// QuizSubmissionDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { CheckCircle, Error, AccessTime } from "@mui/icons-material";

const QuizSubmissionDialog = ({
  open,
  onClose,
  title = "Quiz Submitted",
  message = "Your quiz has been successfully submitted.",
  status = "success", // 'success', 'error', or 'timeout'
  buttonText = "Return to Quiz List",
  showScoreButton = false,
  onViewScore,
}) => {
  // Determine icon and styles based on status
  const getIcon = () => {
    switch (status) {
      case "success":
        return <CheckCircle sx={{ fontSize: 40, color: '#4CAF50' }} />;
      case "error":
        return <Error sx={{ fontSize: 40, color: '#D32F2F' }} />;
      case "timeout":
        return <AccessTime sx={{ fontSize: 40, color: '#FF9800' }} />;
      default:
        return <CheckCircle sx={{ fontSize: 40, color: '#4CAF50' }} />;
    }
  };
  
  const getColor = () => {
    switch (status) {
      case "success": return '#4CAF50';
      case "error": return '#D32F2F';
      case "timeout": return '#FF9800';
      default: return '#4CAF50';
    }
  };
  
  const getBgColor = () => {
    switch (status) {
      case "success": return 'rgba(76, 175, 80, 0.1)';
      case "error": return 'rgba(211, 47, 47, 0.1)';
      case "timeout": return 'rgba(255, 152, 0, 0.1)';
      default: return 'rgba(76, 175, 80, 0.1)';
    }
  };

  const getButtonGradient = () => {
    switch (status) {
      case "success": return 'linear-gradient(90deg, #4CAF50 0%, #8BC34A 100%)';
      case "error": return 'linear-gradient(90deg, #D32F2F 0%, #F44336 100%)';
      case "timeout": return 'linear-gradient(90deg, #FF9800 0%, #FFC107 100%)';
      default: return 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px',
          maxWidth: '400px',
          width: '100%'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: getColor() }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: getBgColor(), 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          {getIcon()}
        </Box>
        <Typography variant="body1" textAlign="center">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ 
        padding: '0 24px 24px 24px', 
        justifyContent: showScoreButton ? 'space-between' : 'center',
        flexDirection: showScoreButton ? 'row' : 'column',
        gap: 1
      }}>
        {showScoreButton && (
          <Button
            onClick={onViewScore}
            variant="outlined"
            sx={{
              borderRadius: '8px',
              borderColor: getColor(),
              color: getColor(),
              '&:hover': {
                borderColor: getColor(),
                backgroundColor: getBgColor(),
              }
            }}
          >
            View Score
          </Button>
        )}
        
        <Button 
          onClick={onClose}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: status === 'success' ? 
              'linear-gradient(90deg, #4CAF50 0%, #8BC34A 100%)' : 
              status === 'error' ? 
              'linear-gradient(90deg, #D32F2F 0%, #F44336 100%)' : 
              'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px',
            color: 'white'
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuizSubmissionDialog;