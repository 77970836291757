import React, { useEffect, useState } from "react";
import "./style.css";
import {
  ArrowBack,
  Delete,
  DeleteOutline,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  ShareOutlined,
  StarBorderOutlined,
  Star,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  CircularProgress,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MicroscopeSlideViewer from "../../test/MicroscopeSlideViewer";
import UploadSlideModal from "../../Components/UploadSlideModal/UploadSlideModal";
import TileGenProgressBar from "../../Components/TileGenProgressBar";
import { API_URL } from "../../env";
import ShareModal from "../../Components/Share/ShareModal";
import FlagModal from "../../Components/Flag/FlagModal";

const SlideArchive = ({ currentCaseId, setopenWorklist, openWorklist }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [slides, setSlides] = useState([]); // Changed from cases to slides

  const [open, setOpen] = useState(false);
  const [openWorkspace, setOpenWorkspace] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState({}); // Changed from selectedCases
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSlideId, setCurrentSlideId] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [tileGenProgress, setTileGenProgress] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [selectedSlideForShare, setSelectedSlideForShare] = useState(null);
  const [starredSlides, setStarredSlides] = useState({});
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const [selectedCaseIdForFlag, setSelectedCaseIdForFlag] = useState(null);
  const [isSlide, setIsSlide] = useState(null);
  const [selectedCaseForFlag, setSelectedCaseForFlag] = useState(null);
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Function to parse and clean tags
  const parseTags = (tags) => {
    if (!tags || !Array.isArray(tags) || tags.length === 0) {
      return [];
    }
    
    try {
      // Handle case where tags are stored as JSON strings
      if (typeof tags[0] === 'string') {
        // Special case for the specific format in your data "[\"ajay\"", "\"ajay1\"]"
        if (tags.length === 2 && tags[0].startsWith('[\"') && tags[1].endsWith('\"]')) {
          // Combine the tags and parse them
          const combinedTag = tags[0] + "," + tags[1];
          const cleanedTag = combinedTag.replace(/\[|\]/g, '');
          return JSON.parse("[" + cleanedTag + "]");
        }
        
        // Try to parse each tag individually
        return tags.map(tag => {
          // Remove extra quotes and formatting
          const cleanTag = tag.replace(/^\[?"\\?"|"\\?"\]?$/g, '');
          try {
            return cleanTag;
          } catch {
            return cleanTag;
          }
        });
      }
      
      return tags;
    } catch (error) {
      console.error("Error parsing tags:", error);
      return tags.map(tag => String(tag));
    }
  };

  const constructQueryString = () => {
    const params = new URLSearchParams();

    // Add page parameter
    params.append("page", page);

    // Add search term if it exists
    if (searchTerm.trim()) {
      params.append("search", searchTerm.trim());
    }

    return params.toString();
  };

  useEffect(() => {
    const fetchSlides = async () => {
      setIsLoading(true);
      try {
        const queryString = constructQueryString();
        const response = await fetch(
          `${API_URL}slides/${currentCaseId}?${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch slides");
        }

        const data = await response.json();

        // Update slides with search results
        setSlides(data.slides || []);
        setTotalPages(data.total_pages);

        // Initialize starred status
        const starredStatus = {};
        data.slides?.forEach((slide) => {
          starredStatus[slide.SlideId] = slide.is_starred || false;
        });
        setStarredSlides(starredStatus);
        
        // Show refresh success notification if triggered by upload completion
        if (tileGenProgress === 100) {
          setShowRefreshMessage(true);
          setTileGenProgress(0);
        }
        
      } catch (error) {
        console.error("Error fetching slides:", error);
        // Optionally show an error message
        Swal.fire({
          title: "Error",
          text: "Failed to fetch slides. Please try again.",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Add debounce to search
    const timeoutId = setTimeout(() => {
      fetchSlides();
    }, 500); // 500ms delay

    return () => clearTimeout(timeoutId);
  }, [currentCaseId, page, searchTerm, refresh]);

  // Force refresh when tile generation completes
  useEffect(() => {
    if (tileGenProgress === 100) {
      // Small delay to ensure tile generation has time to finalize
      const timeoutId = setTimeout(() => {
        setRefresh(!refresh);
      }, 1000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [tileGenProgress]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to first page when searching
  };

  const handleStarClick = async (slideId) => {
    try {
      const isCurrentlyStarred = starredSlides[slideId] || false;
      const response = await fetch(
        `${API_URL}slide_status/slides/${slideId}/star`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            is_starred: !isCurrentlyStarred,
          }),
        }
      );

      if (response.ok) {
        setStarredSlides((prev) => ({
          ...prev,
          [slideId]: !isCurrentlyStarred,
        }));
      }
    } catch (error) {
      console.error("Error updating star status:", error);
    }
  };

  const handleSelectAll = (e) => {
    const newSelectedSlides = {};
    if (e.target.checked) {
      slides.forEach((slide) => {
        newSelectedSlides[slide.SlideId] = true;
      });
    }
    setSelectedSlides(newSelectedSlides);
  };

  const handleSelectSlide = (SlideId) => {
    setSelectedSlides((prevSelected) => ({
      ...prevSelected,
      [SlideId]: !prevSelected[SlideId],
    }));
  };

  const isSlideSelected = (SlideId) => !!selectedSlides[SlideId];

  const isAllSelected =
    slides.length > 0 && Object.keys(selectedSlides).length === slides.length;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFlagClose = () => {
    setIsSlide(false);
    setIsFlagModalOpen(false);
    setSelectedCaseIdForFlag(null);
  };
  
  const handleFlagOpen = (caseId) => {
    const caseToOpen = slides.find((c) => c.SlideId === caseId);
    if (caseToOpen) {
      setSelectedCaseForFlag(caseToOpen);
      setIsSlide(true);
      setSelectedCaseIdForFlag(caseId);
      setIsFlagModalOpen(true);
    }
  };
  
  const handleUpload = () => {
    setOpen(true);
    setUploadProgress(0);
    setTimeLeft(0);
    setTileGenProgress(0);
  };

  const handleRowDoubleClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  const handleViewDetailsClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  const deleteSlide = async (slideId) => {
    try {
      const response = await fetch(`${API_URL}slides/${slideId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error deleting slide:", error);
      return false;
    }
  };

  const handleDeleteSlide = async (slideId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const isDeleted = await deleteSlide(slideId);
        if (isDeleted) {
          Swal.fire("Deleted!", "Your slide has been deleted.", "success");
          setRefresh((prev) => !prev);
        }
      }
    });
  };

  const shareModalClick = (slideId) => {
    setSelectedSlideForShare(slideId);
    setShareModal(true);
  };
  
  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.slice(0, limit)}...` : text;
  };

  // Function to handle bulk delete of selected slides
  const handleBulkDelete = () => {
    const selectedSlideIds = Object.keys(selectedSlides).filter(id => selectedSlides[id]);
    
    if (selectedSlideIds.length === 0) {
      Swal.fire({
        title: "No slides selected",
        text: "Please select at least one slide to delete.",
        icon: "info",
      });
      return;
    }
    
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to delete ${selectedSlideIds.length} slide(s). This action cannot be reverted!`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete them!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let successCount = 0;
        let failureCount = 0;
        
        // Show loading indicator during bulk deletion
        Swal.fire({
          title: "Deleting slides...",
          text: "Please wait",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        
        // Process all deletions sequentially
        for (const slideId of selectedSlideIds) {
          try {
            const isDeleted = await deleteSlide(slideId);
            if (isDeleted) {
              successCount++;
            } else {
              failureCount++;
            }
          } catch (error) {
            console.error(`Error deleting slide ${slideId}:`, error);
            failureCount++;
          }
        }
        
        // Reset selections
        setSelectedSlides({});
        
        // Show results and refresh
        if (successCount > 0) {
          Swal.fire({
            title: "Deletion Complete",
            text: `Successfully deleted ${successCount} slide(s)${failureCount > 0 ? `, failed to delete ${failureCount} slide(s)` : ''}`,
            icon: successCount > 0 ? "success" : "warning",
          });
          setRefresh(!refresh);
        } else {
          Swal.fire({
            title: "Deletion Failed",
            text: "Failed to delete any slides. Please try again.",
            icon: "error",
          });
        }
      }
    });
  };

  return (
    <>
      {openWorkspace ? (
        <MicroscopeSlideViewer
          currentSlideId={currentSlideId}
          openWorkspace={openWorkspace}
          setOpenWorkspace={setOpenWorkspace}
          currentImageUrl={currentImageUrl}
        />
      ) : (
        <div className="worklist-container">
          <div className="worklist-header">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setopenWorklist(!openWorklist)}
            >
              <ArrowBack />
            </div>
            <div className="workspace-header-title">My Workspace</div>
            <div className="workSpace-filterBox">
              <div className="search-worklist">
                <input
                  style={{ border: "none" }}
                  className="search-bar"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div>
                  <Search />
                </div>
              </div>
              <div className="create-upload" onClick={handleUpload}>
                Upload
              </div>
            </div>
          </div>

          <div className="worklist-head">
            <div 
              className="delete-btn" 
              onClick={handleBulkDelete}
              style={{ cursor: "pointer" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Delete fontSize="12px" />
              </div>
              <div>Delete Selected</div>
            </div>
          </div>

          <div className="table-container">
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            ) : slides.length === 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ mb: 2 }}>No slides found</Typography>
                <Typography variant="body2" color="text.secondary">
                  {searchTerm ? "Try adjusting your search criteria" : "Upload slides to get started"}
                </Typography>
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "0px 0px 5px 0px",
                        fontSize: 12,
                        fontWeight: "600",
                        borderBottom: "none",
                      }}
                    >
                      <Checkbox
                        className="small-checkbox"
                        indeterminate={
                          Object.keys(selectedSlides).length > 0 && !isAllSelected
                        }
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell className="table-head">Thumbnail</TableCell>
                    <TableCell className="table-head">Name</TableCell>
                    <TableCell className="table-head">Tags</TableCell>
                    <TableCell className="table-head">Shared</TableCell>
                    <TableCell className="table-head">Size</TableCell>
                    <TableCell
                      style={{
                        padding: "0px 5px 5px 0px",
                        textAlign: "end",
                        fontSize: 12,
                        fontWeight: "600",
                        borderBottom: "none",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slides?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        className="table-row"
                        onDoubleClick={() =>
                          handleRowDoubleClick(row.SlideId, row.ImageUrl)
                        }
                      >
                        <TableCell
                          style={{
                            borderTopLeftRadius: 12,
                            borderBottomLeftRadius: 12,
                          }}
                          className="table-cell"
                        >
                          <Checkbox
                            className="small-checkbox"
                            checked={isSlideSelected(row.SlideId)}
                            onChange={() => handleSelectSlide(row.SlideId)}
                          />
                        </TableCell>
                        <TableCell className="table-cell">
                          <img
                            style={{
                              margin: 0,
                              width: 90,
                              height: 60,
                              borderRadius: 8,
                            }}
                            src={`https://path-image.s3.amazonaws.com/${row.Thumbnail}`}
                            alt={row.SlideName}
                          />
                        </TableCell>
                        <TableCell className="table-cell">
                          {truncateText(row.SlideName, 10)}
                        </TableCell>
                        <TableCell className="table-cell">
                          <div className="tags">
                            {parseTags(row?.Tags)?.map((tag, idx) => (
                              <div key={idx} className="workspace-tag">
                                {tag}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className="table-cell">
                          {row.Shared.length > 0 ? "Yes" : "No"}
                        </TableCell>
                        <TableCell className="table-cell">
                          {parseFloat(row.Size).toFixed(2) + " Mb"}
                        </TableCell>
                        <TableCell
                          style={{
                            borderTopRightRadius: 12,
                            borderBottomRightRadius: 12,
                            textAlign: "end",
                            width: 230,
                          }}
                          className="table-cell"
                        >
                          <IconButton
                            className="iconButton"
                            onClick={() => handleStarClick(row.SlideId)}
                          >
                            {starredSlides[row.SlideId] ? (
                              <Star
                                className="verifiedIcon"
                                style={{ color: "#FFD700" }}
                              />
                            ) : (
                              <StarBorderOutlined className="verifiedIcon" />
                            )}
                          </IconButton>
                          <IconButton
                            onClick={() => handleFlagOpen(row.SlideId)}
                            className="FlagIconButton"
                          >
                            <FlagOutlined
                              style={{
                                fontSize: "16px",
                                color: row.is_flagged ? "#FB3B42" : "grey",
                              }}
                            />
                          </IconButton>
                          <IconButton className="iconButton">
                            <ShareOutlined
                              onClick={() => shareModalClick(row.SlideId)}
                              className="shareIcon"
                            />
                          </IconButton>
                          <IconButton className="iconButton">
                            <RemoveRedEyeOutlined
                              className="removeRedEyeIcon"
                              onClick={() =>
                                handleViewDetailsClick(row.SlideId, row.ImageUrl)
                              }
                            />
                          </IconButton>
                          <IconButton
                            className="iconButton"
                            onClick={() => handleDeleteSlide(row.SlideId)}
                          >
                            <DeleteOutline className="deleteIcon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <div style={{ height: "4px" }}></div>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>

          {open && (
            <UploadSlideModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={open}
              setOpen={setOpen}
              currentCaseId={currentCaseId}
              setUploadProgress={setUploadProgress}
              setTimeLeft={setTimeLeft}
              setTileGenProgress={setTileGenProgress}
            />
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box
              sx={{
                position: "fixed",
                bottom: 16,
                right: 16,
                display: "flex",
                alignItems: "center",
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
                width: "200px",
                p: 1,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={40}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "40px",
                  ml: 1,
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`${Math.round(uploadProgress)}%`}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ ml: 2 }}>
                {timeLeft > 0 ? `${timeLeft}s left` : "Uploading..."}
              </Typography>
            </Box>
          )}

          {tileGenProgress > 0 && tileGenProgress < 100 && (
            <TileGenProgressBar progress={tileGenProgress} />
          )}
          
          {/* Success message after upload complete */}
          <Snackbar
            open={showRefreshMessage}
            autoHideDuration={5000}
            onClose={() => setShowRefreshMessage(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert 
              onClose={() => setShowRefreshMessage(false)} 
              severity="success" 
              sx={{ width: '100%' }}
            >
              Slide successfully uploaded and workspace refreshed!
            </Alert>
          </Snackbar>
          
          {isFlagModalOpen && selectedCaseIdForFlag !== null && (
            <FlagModal
              isOpen={isFlagModalOpen}
              isSlide={isSlide}
              onClose={handleFlagClose}
              caseId={selectedCaseIdForFlag}
              flagReason={selectedCaseForFlag.flag_reason || ""}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}
          {shareModal && (
            <ShareModal
              shareModal={shareModal}
              slideId={selectedSlideForShare}
              setShareModal={setShareModal}
              onClose={() => setShareModal(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SlideArchive;