import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Chip,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// Constants
const QUESTION_TYPES = {
  multiple_choice: "Multiple Choice",
  true_false: "True/False",
  short_answer: "Short Answer",
};

const QuestionDetailsModal = ({ open, onClose, question, onEdit }) => {
  // Parse JSON safely
  const safelyParseJSON = (jsonString, fallback = {}) => {
    try {
      return typeof jsonString === "string"
        ? JSON.parse(jsonString)
        : jsonString;
    } catch (error) {
      console.error("JSON parsing error:", error);
      return fallback;
    }
  };

  if (!question) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          background: "linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        },
      }}
    >
      <DialogTitle
        sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)", py: 1, pr: 5 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="subtitle1">Question Details</Typography>
          <Chip
            label={
              QUESTION_TYPES[question.question_type] || question.question_type
            }
            size="small"
            sx={{
              bgcolor: "#E3F2FD",
              color: "#2279F5",
              fontSize: "0.7rem",
            }}
          />
          {question.score && (
            <Chip
              label={`${question.score} pts`}
              size="small"
              sx={{
                bgcolor: "#FFF8E1",
                color: "#FFA000",
                fontSize: "0.7rem",
              }}
            />
          )}
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          backgroundImage:
            "linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        }}
      >
        <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
          {question.question}
        </Typography>

        {question.question_type === "multiple_choice" && (
          <Box sx={{ ml: 1, mt: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, fontSize: "0.8rem" }}>
              Answer Options:
            </Typography>
            {Object.entries(safelyParseJSON(question.options, {})).map(
              ([key, value]) => {
                const correctAnswers = safelyParseJSON(
                  question.correct_answer,
                  []
                );
                const isCorrect = Array.isArray(correctAnswers)
                  ? correctAnswers.includes(key)
                  : correctAnswers === key;

                return (
                  <Typography
                    key={key}
                    variant="body2"
                    sx={{
                      mb: 0.5,
                      p: 0.75,
                      borderRadius: "4px",
                      fontSize: "0.8rem",
                      bgcolor: isCorrect
                        ? "rgba(76, 175, 80, 0.1)"
                        : "transparent",
                      color: isCorrect ? "success.main" : "text.primary",
                    }}
                  >
                    {key}: {value}
                  </Typography>
                );
              }
            )}
          </Box>
        )}

        {question.question_type === "true_false" && (
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontSize: "0.8rem" }}
            >
              Correct Answer:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                p: 0.75,
                borderRadius: "4px",
                bgcolor: "rgba(76, 175, 80, 0.1)",
                color: "success.main",
                display: "inline-block",
                fontSize: "0.8rem",
              }}
            >
              {question.correct_answer === "true" ? "True" : "False"}
            </Typography>
          </Box>
        )}

        {question.question_type === "short_answer" && (
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontSize: "0.8rem" }}
            >
              Correct Answer:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                p: 0.75,
                borderRadius: "4px",
                bgcolor: "rgba(76, 175, 80, 0.1)",
                color: "success.main",
                display: "inline-block",
                fontSize: "0.8rem",
              }}
            >
              {question.correct_answer}
            </Typography>
          </Box>
        )}

        {question.explanation && (
          <Box
            sx={{
              mt: 2,
              p: 1.5,
              bgcolor: "rgba(255, 255, 255, 0.65)",
              borderRadius: "6px",
              borderLeft: "4px solid #2279F5",
              boxShadow: "0 2px 5px rgba(0,0,0,0.08)",
              position: "relative",
              overflow: "hidden",
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                width: "8px",
                height: "100%",
                background: "linear-gradient(180deg, #2279F5 0%, #00D2FE 100%)",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              color="#2279F5"
              sx={{ fontSize: "0.85rem", fontWeight: 600 }}
            >
              Explanation:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
              {question.explanation}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          py: 1,
          backgroundImage:
            "linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
        }}
      >
        <Button onClick={() => onEdit(question)} color="primary" size="small">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDetailsModal;
