import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button"; // Added for preview button
import PreviewIcon from "@mui/icons-material/Preview"; // Import preview icon
import { API_URL } from "../../../env";
import Swal from "sweetalert2";

export default function ModuleContentsModal({
  open,
  setOpen,
  setRefresh,
  moduleData,
  selectedCourseId,
  selectedModuleId,
  onSuccess,
}) {
  const [formData, setFormData] = useState({
    ModuleId: selectedModuleId, // Added for updates
    CourseId: selectedCourseId || "",
    Title: "",
    Description: "",
    ContentType: "", // Added required field
    ContentReference: "", // Added optional field
    Duration: "",
    Sequence: 1,
    IsRequired: false, // Added required boolean field
    Status: true, // Keep this field even though not in API docs
    content_file: null, // Added for file uploads
    thumbnail: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false); // State for preview modal

  // Populate form when editing
  useEffect(() => {
    if (moduleData) {
      console.log("Populating form with module data:", moduleData);
      setFormData({
        ModuleId: selectedModuleId || moduleData.ModuleId || "",
        ContentId: moduleData.ContentId || "", // Add this line to include ContentId
        CourseId: moduleData.CourseId || selectedCourseId || "",
        Title: moduleData.Title || "",
        Description: moduleData.Description || "",
        ContentType: moduleData.ContentType || "",
        ContentReference: moduleData.ContentReference || "",
        Duration: moduleData.Duration || "",
        Sequence: moduleData.Sequence || 1,
        IsRequired:
          moduleData.IsRequired !== undefined ? moduleData.IsRequired : false,
        Status: moduleData.Status !== undefined ? moduleData.Status : true,
        content_file: null,
        thumbnail: null,
      });
    } else {
      // For new modules, always use the selectedModuleId
      setFormData({
        ModuleId: selectedModuleId,
        CourseId: selectedCourseId || "",
        Title: "",
        Description: "",
        ContentType: "",
        ContentReference: "",
        Duration: "",
        Sequence: 1,
        IsRequired: false,
        Status: true,
        content_file: null,
        thumbnail: null,
      });
    }
  }, [moduleData, selectedCourseId, selectedModuleId, open]);

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  const handleInputChange = (field) => (event) => {
    const value =
      field === "Status" || field === "IsRequired"
        ? event.target.checked
        : event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (field) => (event) => {
    if (event.target.files && event.target.files[0]) {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.files[0],
      }));
    }
  };

  // Toggle preview modal
  const togglePreview = () => {
    setPreviewOpen(!previewOpen);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // Validate required fields
      const requiredFields = ["Title", "Sequence", "ContentType"];
      const missingFields = requiredFields.filter(
        (field) =>
          formData[field] === "" ||
          formData[field] === null ||
          formData[field] === undefined
      );

      // Also check if CourseId is missing
      if (!formData.CourseId) {
        missingFields.push("CourseId");
      }

      if (missingFields.length > 0) {
        setError(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
        setLoading(false);
        return;
      }

      // Create FormData object for file upload
      const submitData = new FormData();

      // Add all fields to FormData
      Object.keys(formData).forEach((key) => {
        if (key === "thumbnail" || key === "content_file") {
          if (formData[key]) {
            submitData.append(key, formData[key]);
          }
        } else {
          submitData.append(key, formData[key]);
        }
      });

      // Determine if we're creating or updating
      // Use ContentId instead of ModuleId for the check
      const isUpdate = moduleData && moduleData.ContentId;
      
      // Use ContentId for the update endpoint
      const url = isUpdate
        ? `${API_URL}module/content/${moduleData.ContentId}`
        : `${API_URL}module/content`;

      const method = isUpdate ? "PUT" : "POST";

      console.log(`Submitting ${method} request to ${url}`);

      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: submitData,
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(
          `HTTP error! status: ${response.status}${
            errorData ? ` - ${JSON.stringify(errorData)}` : ""
          }`
        );
      }

      const result = await response.json();
      
      // Show success message
      Swal.fire({
        title: isUpdate ? "Updated!" : "Created!",
        text: isUpdate 
          ? "Module content has been updated successfully." 
          : "New module content has been created successfully.",
        icon: "success",
      });
      
      // Call the success callback and close the modal
      if (onSuccess) onSuccess();
      if (setRefresh) setRefresh((prev) => !prev);
      handleClose();
    } catch (err) {
      console.error(
        moduleData ? "Error updating module content:" : "Error creating module content:",
        err
      );
      
      setError(
        moduleData
          ? "Failed to update module content. Please try again."
          : "Failed to create module content. Please try again."
      );
      
      Swal.fire({
        title: "Error",
        text: moduleData
          ? "Failed to update module content. Please try again."
          : "Failed to create module content. Please try again.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const renderTextField = (label, field, required = false, type = "text") => (
    <Grid item xs={6}>
      <label className="label">
        {label}
        {required ? "*" : ""}
      </label>
      <TextField
        fullWidth
        type={type}
        value={formData[field] || ""}
        onChange={handleInputChange(field)}
        variant="outlined"
        sx={textFieldStyles}
      />
    </Grid>
  );

  // Preview Modal Component
  const PreviewModal = () => (
    <Modal
      aria-labelledby="preview-modal-title"
      aria-describedby="preview-modal-description"
      open={previewOpen}
      onClose={togglePreview}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={previewOpen}>
        <Box
          className="preview-box"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <IconButton
            onClick={togglePreview}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "white",
              color: "black",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" id="preview-modal-title" sx={{ mb: 2, fontWeight: 600 }}>
            {formData.Title || "Untitled Content"}
          </Typography>
          
          {formData.ContentType && (
            <Typography variant="subtitle1" sx={{ mb: 1, color: "primary.main" }}>
              Content Type: {formData.ContentType.charAt(0).toUpperCase() + formData.ContentType.slice(1)}
            </Typography>
          )}
          
          {formData.Duration && (
            <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
              Duration: {formData.Duration}
            </Typography>
          )}
          
          <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
            Description
          </Typography>
          <Box sx={{ 
            p: 2, 
            bgcolor: "#f5f5f5", 
            borderRadius: 1,
            border: "1px solid #e0e0e0",
            whiteSpace: "pre-wrap" 
          }}>
            <Typography variant="body1">
              {formData.Description || "No description provided."}
            </Typography>
          </Box>
          
          {formData.ContentReference && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                Content Reference
              </Typography>
              <Box sx={{ p: 2, bgcolor: "#f5f5f5", borderRadius: 1, border: "1px solid #e0e0e0" }}>
                <Typography variant="body1" component="div">
                  {formData.ContentReference}
                </Typography>
              </Box>
            </>
          )}
          
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2">
              {formData.IsRequired ? "Required content" : "Optional content"}
            </Typography>
            <Typography variant="body2">
              Sequence: {formData.Sequence}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="courses-box" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {moduleData ? "Edit Module Content" : "Create New Module Content"}
            </Typography>
            <Box noValidate autoComplete="off">
              <Grid container spacing={2}>
                {renderTextField("Title", "Title", true)}
                <Grid item xs={12}>
                  <label
                    className="label"
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      display: "block",
                    }}
                  >
                    Description
                  </label>
                  <TextField
                    fullWidth
                    value={formData.Description || ""}
                    onChange={handleInputChange("Description")}
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={{ background: "white" }}
                  />
                </Grid>

                {/* Content Type Dropdown - Required */}
                <Grid item xs={6}>
                  <label className="label">Content Type*</label>
                  <FormControl fullWidth sx={textFieldStyles}>
                    <Select
                      value={formData.ContentType || ""}
                      onChange={handleInputChange("ContentType")}
                      displayEmpty
                    >
                      <MenuItem value="">Select Type</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="case">Case</MenuItem>
                      <MenuItem value="quiz">Quiz</MenuItem>
                      <MenuItem value="image">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {renderTextField(
                  "Content Reference",
                  "ContentReference",
                  false
                )}
                {renderTextField("Duration", "Duration", false, "text")}
                {renderTextField("Sequence", "Sequence", true, "number")}

                {/* IsRequired Switch */}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(formData.IsRequired)}
                        onChange={handleInputChange("IsRequired")}
                        color="primary"
                      />
                    }
                    label="Is Required"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>

                {/* Status Switch */}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(formData.Status)}
                        onChange={handleInputChange("Status")}
                        color="primary"
                      />
                    }
                    label="Status"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>

                {/* Content File Upload */}
                <Grid item xs={6}>
                  <label className="label">Content File</label>
                  <TextField
                    fullWidth
                    type="file"
                    onChange={handleFileChange("content_file")}
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      "& .MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    }}
                  />
                </Grid>

                {/* Thumbnail Upload */}
                <Grid item xs={6}>
                  <label className="label">Thumbnail</label>
                  <TextField
                    fullWidth
                    type="file"
                    onChange={handleFileChange("thumbnail")}
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      "& .MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        accept: "image/*",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            
            {/* Preview Button */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Button 
                variant="outlined" 
                startIcon={<PreviewIcon />}
                onClick={togglePreview}
                sx={{
                  borderRadius: "20px",
                  padding: "6px 16px",
                  textTransform: "none",
                  fontSize: "0.875rem",
                }}
              >
                Preview Content
              </Button>
            </Box>
            
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit}
                style={{
                  opacity: loading ? 0.7 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                {loading ? "Submitting..." : moduleData ? "Update" : "Submit"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      
      {/* Render the Preview Modal */}
      {previewOpen && <PreviewModal />}
    </div>
  );
}