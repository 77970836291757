import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Chip,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  TimerOutlined,
  CheckCircleOutlined,
  QuizOutlined,
  HistoryOutlined,
  PlayArrowOutlined,
  GradingOutlined,
  InfoOutlined,
} from "@mui/icons-material";

const StudentQuizView = ({ quiz, totalQuestions, results }) => {
  const navigate = useNavigate();
  const [maxAttemptsDialog, setMaxAttemptsDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
console.log(quiz);

  // Placeholder results in case none are provided
  const quizResults = results || {
    completed: false,
    score: 0,
    totalScore: 0,
    percentageScore: 0,
    passedStatus: false,
    dateCompleted: null,
    attemptsRemaining: quiz?.max_attempts || 1,
    totalAttempts: quiz?.max_attempts || 1,
  };

  // logCalculate percentage score for progress bar
  const scorePercentage =
    Math.round((quizResults.score / quizResults.totalScore) * 100) || 0;

  const formatDate = (dateString) => {
    if (!dateString) return "Not completed";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Handle start quiz button click
  const handleStartQuiz = () => {
    try {
      navigate(`/take-quiz/${quiz.quiz_id}`);
    } catch (error) {
      // If there's an error with maximum attempts, show the dialog
      if (error.message && error.message.includes("Maximum attempts")) {
        setErrorMessage(error.message);
        setMaxAttemptsDialog(true);
      } else {
        console.error("Error starting quiz:", error);
      }
    }
  };

  const renderQuizInfo = () => (
    <Card
      sx={{
        mb: 3,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
        borderRadius: "12px",
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.08)",
      }}
    >
      <Box
        sx={{
          p: 2,
          background: "linear-gradient(135deg, #2279F5 0%, #00D2FE 100%)",
          color: "white",
        }}
      >
        <Typography variant="h6" fontWeight="600">
          Quiz Information
        </Typography>
        <Typography variant="body2">
          Complete this quiz to test your knowledge
        </Typography>
      </Box>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              {quiz?.description || "No description provided"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <TimerOutlined
                sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
              />
              <Typography variant="subtitle2">Time Limit:</Typography>
            </Box>
            <Typography variant="body1" fontWeight="500">
              {quiz?.time_limit
                ? `${quiz.time_limit} minutes`
                : "No time limit"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <CheckCircleOutlined
                sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
              />
              <Typography variant="subtitle2">Passing Score:</Typography>
            </Box>
            <Typography variant="body1" fontWeight="500">
              {quiz?.passing_score}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              <QuizOutlined
                sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
              />
              <Typography variant="subtitle2">Total Questions:</Typography>
            </Box>
            <Typography variant="body1" fontWeight="500">
              {totalQuestions}
            </Typography>
          </Grid>

          {quizResults.totalAttempts > 0 && (
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }}/>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <HistoryOutlined
                  sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
                />
                <Typography variant="subtitle2" mr={1}>Maximum Attempts:</Typography>
                <Typography variant="body1" fontWeight="500">
                  {quiz?.max_attempts}
                  {/* {quizResults.totalAttempts - quizResults.attemptsRemaining}/{quizResults.totalAttempts} */}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );

  const renderResults = () => {
    // Don't show results section if not completed
    if (!quizResults.completed) return null;

    return (
      <Card
        sx={{
          mb: 3,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
          borderRadius: "12px",
          overflow: "hidden",
          border: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box
          sx={{
            p: 2,
            background: quizResults.passedStatus
              ? "linear-gradient(135deg, #4CAF50 0%, #8BC34A 100%)"
              : "linear-gradient(135deg, #FF9800 0%, #FFC107 100%)",
            color: "white",
          }}
        >
          <Typography variant="h6" fontWeight="600">
            {quizResults.passedStatus ? "Quiz Passed!" : "Quiz Results"}
          </Typography>
          <Typography variant="body2">
            {quizResults.passedStatus
              ? "Great job on completing this quiz"
              : "Review your performance"}
          </Typography>
        </Box>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <GradingOutlined
                  sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
                />
                <Typography variant="subtitle2">Your Score:</Typography>
              </Box>
              <Typography variant="h5" fontWeight="600" color="#2279F5">
                {quizResults.score} / {quizResults.totalScore}
              </Typography>
              <Chip
                label={`${quizResults.percentageScore}%`}
                color={quizResults.passedStatus ? "success" : "warning"}
                size="small"
                sx={{ mt: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <CheckCircleOutlined
                  sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
                />
                <Typography variant="subtitle2">Status:</Typography>
              </Box>
              <Chip
                label={quizResults.passedStatus ? "Passed" : "Not Passed"}
                color={quizResults.passedStatus ? "success" : "error"}
                variant="outlined"
                sx={{ fontWeight: "bold" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <HistoryOutlined
                  sx={{ color: "#2279F5", fontSize: "1.2rem", mr: 1 }}
                />
                <Typography variant="subtitle2">Completed On:</Typography>
              </Box>
              <Typography variant="body1">
                {formatDate(quizResults.dateCompleted)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Score Progress:
                </Typography>
                <Box
                  sx={{
                    height: "8px",
                    width: "100%",
                    bgcolor: "rgba(0,0,0,0.1)",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: `${scorePercentage}%`,
                      bgcolor: quizResults.passedStatus ? "#4CAF50" : "#FF9800",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 0.5,
                  }}
                >
                  <Typography variant="caption">0</Typography>
                  <Typography variant="caption">
                    Passing: {quiz?.passing_score}
                  </Typography>
                  <Typography variant="caption">100</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // Error dialog component
  const MaxAttemptsDialog = () => (
    <Dialog 
      open={maxAttemptsDialog} 
      onClose={() => setMaxAttemptsDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Maximum Attempts Reached
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(211, 47, 47, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <InfoOutlined sx={{ fontSize: 40, color: '#D32F2F' }} />
        </Box>
        <Typography variant="body1" textAlign="center">
          {errorMessage || "You have reached the maximum number of attempts allowed for this quiz."}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'center' }}>
        <Button 
          onClick={() => setMaxAttemptsDialog(false)}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ mt: 2, px: 3 }}>
      {renderQuizInfo()}
      {renderResults()}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          variant="contained"
          size="large"
          startIcon={<PlayArrowOutlined />}
          onClick={handleStartQuiz}
          disabled={
            quizResults.attemptsRemaining <= 0 && quizResults.totalAttempts > 0
          }
          sx={{
            background: "linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%)",
            color: "white",
            px: 4,
            py: 1,
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(34, 121, 245, 0.3)",
            "&:hover": {
              background:
                "linear-gradient(0deg, #1e6ad4 8.33%, #00bfe8 91.67%)",
              boxShadow: "0 6px 15px rgba(34, 121, 245, 0.4)",
            },
            "&.Mui-disabled": {
              background: "rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            }
          }}
        >
          {quizResults.completed
            ? quizResults.attemptsRemaining > 0
              ? "Retake Quiz"
              : "Maximum Attempts Reached"
            : "Start Quiz"}
        </Button>
      </Box>

      <MaxAttemptsDialog />
    </Box>
  );
};

export default StudentQuizView;