import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const TileGenProgressBar = ({ progress }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom:90 ,
        right: 16,
        display: "flex",
        alignItems: "center",
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 2,
        p: 1,
      }}
    >
      <CircularProgress variant="determinate" value={progress} size={40} />
      {/* <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(progress)}%`}
        </Typography>
      </Box> */}
      <Typography variant="body2" sx={{ ml: 2 }}>
        Generating Tiles
      </Typography>
    </Box>
  );
};

export default TileGenProgressBar;
