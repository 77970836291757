import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Chip,
  Pagination,
  PaginationItem,
  Alert,
  Snackbar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import { InfoOutlined, Newspaper } from "@mui/icons-material";
import { API_URL } from "../../env";

// Import component files
import InfoBox from "./InfoBox";
import QuestionCreateModal from "./QuestionCreateModal";
import QuestionsTable from "./QuestionTable";
import QuestionDetailsModal from "./QuestionDetailsModal";
import QuizInfoDrawer from "./QuizInfoDrawer";
import StudentAnswerModal from "./StudentAnswerModal";
import StudentQuizView from "./StudentQuizView";
import QuizAttemptService from "./services/QuizAttemptService";
import { PlayArrowOutlined } from "@mui/icons-material";

// Constants
const PAGE_SIZE = 5;

const QuizView = ({ selectedQuiz, setQuizView }) => {
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [openStudentAnswerModal, setOpenStudentAnswerModal] = useState(false);
  const [quizResults, setQuizResults] = useState(null);
  
  // Error dialog state for maximum attempts
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const displayNotification = (message, severity = "success") => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setShowNotification(true);
  };

  // Additional state for notification severity
  const [notificationSeverity, setNotificationSeverity] = useState("success");

  // Fetch quiz and questions data
  const fetchQuizData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch quiz details
      const quizResponse = await fetch(
        `${API_URL}quizz/${selectedQuiz.quiz_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!quizResponse.ok) {
        throw new Error(`Failed to fetch quiz: ${quizResponse.status}`);
      }

      const quizData = await quizResponse.json();
      setQuiz(quizData);

      // Fetch questions with pagination
      const questionsResponse = await fetch(
        `${API_URL}quizz/${selectedQuiz.quiz_id}/questions?page=${currentPage}&page_size=${PAGE_SIZE}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!questionsResponse.ok) {
        throw new Error(
          `Failed to fetch questions: ${questionsResponse.status}`
        );
      }

      const questionsData = await questionsResponse.json();
      setQuestions(questionsData.questions || []);
      setTotalPages(questionsData.total_pages || 1);
      setTotalQuestions(questionsData.total_questions || 0);
    } catch (error) {
      console.error("Error fetching quiz data:", error);
      setError(error.message);
      displayNotification(`Error: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, [selectedQuiz.quiz_id, currentPage]);

  useEffect(() => {
    fetchQuizData();
  }, [fetchQuizData]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_URL}users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserData(data);

        // If user is a student, fetch their quiz results
        if (data && data.AccessLevel === "student") {
          fetchStudentQuizResults();
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Fetch student quiz results
  const fetchStudentQuizResults = async () => {
    try {
      // This would be your actual API endpoint to get student's quiz results
      // For now, we'll use a placeholder
      /*
      const response = await fetch(`${API_URL}quizz/${selectedQuiz.quiz_id}/results`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch quiz results");
      }

      const data = await response.json();
      setQuizResults(data);
      */

      // Placeholder data - replace with actual API call
      setQuizResults({
        completed: false,
        score: 0,
        totalScore: 0,
        percentageScore: 0,
        passedStatus: false,
        dateCompleted: null,
        attemptsRemaining: quiz?.max_attempts || 1,
        totalAttempts: quiz?.max_attempts || 1,
      });
    } catch (err) {
      console.error("Error fetching quiz results:", err);
    }
  };

  // Handle question deletion
  const handleDeleteQuestion = async (questionId) => {
    try {
      const response = await fetch(
        `${API_URL}quizz/${selectedQuiz.quiz_id}/questions/${questionId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to delete question: ${response.status}`);
      }

      fetchQuizData();
      displayNotification("Question deleted successfully");
    } catch (error) {
      console.error("Error deleting question:", error);
      displayNotification(`Error: ${error.message}`, "error");
    }
  };

  const handleRowClick = (question) => {
    setSelectedQuestion(question);

    // If user is a student, open the answer modal instead of details modal
    if (userData && userData.AccessLevel === "student") {
      setOpenStudentAnswerModal(true);
    } else {
      setOpenDetailsModal(true);
    }
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedQuestion(null);
  };

  const handleEditQuestion = (question) => {
    setSelectedQuestion(question);
    setOpenQuestionModal(true);
    setOpenDetailsModal(false);
  };

  const handleCloseModal = () => {
    setOpenQuestionModal(false);
    setSelectedQuestion(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleStartQuiz = async () => {
    try {
      // Show loading notification
      displayNotification("Starting quiz...", "info");
      
      const now = new Date();
      
      // Attempt to create a quiz attempt
      await QuizAttemptService.createAttempt({
        quiz_id: selectedQuiz.quiz_id,
        start_time: now.toISOString(),
        status: "in_progress",
      });
      
      // If successful, navigate to the quiz
      navigate(`/take-quiz/${selectedQuiz.quiz_id}`);
    } catch (error) {
      console.error("Error starting quiz:", error);
      
      // Check if it's a maximum attempts error
      if (error.message && error.message.includes("MAX_ATTEMPTS_REACHED")) {
        // Extract the error message
        const errorMsg = error.message.replace("MAX_ATTEMPTS_REACHED: ", "");
        
        // Show error dialog
        setErrorDialog({
          open: true,
          title: "Maximum Attempts Reached",
          message: errorMsg || "You have reached the maximum number of attempts allowed for this quiz.",
        });
        
        // Update quiz results to show max attempts reached
        if (quizResults) {
          setQuizResults({
            ...quizResults,
            attemptsRemaining: 0
          });
        }
      } else {
        // For other errors, show notification
        displayNotification(`Error starting quiz: ${error.message}`, "error");
      }
    }
  };

  // Handle error dialog close
  const handleErrorDialogClose = () => {
    setErrorDialog(prev => ({...prev, open: false}));
  };

  // Calculate the starting index for the current page
  const startIndex = (currentPage - 1) * PAGE_SIZE;

  if (loading && !quiz) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error && !quiz) {
    return (
      <Box sx={{ my: 4, p: 2 }}>
        <Alert severity="error">
          {error}
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 2 }}
            onClick={fetchQuizData}
          >
            Retry
          </Button>
        </Alert>
      </Box>
    );
  }

  // Check if the user is a student
  const isStudent = userData && userData.AccessLevel === "student";

  // Error dialog component
  const ErrorDialog = () => (
    <Dialog 
      open={errorDialog.open} 
      onClose={handleErrorDialogClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {errorDialog.title}
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(211, 47, 47, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <InfoOutlined sx={{ fontSize: 40, color: '#D32F2F' }} />
        </Box>
        <Typography variant="body1" textAlign="center">
          {errorDialog.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'center' }}>
        <Button 
          onClick={handleErrorDialogClose}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="worklist-container">
      {/* Error Dialog */}
      <ErrorDialog />
      
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          padding: "30px 20px 0px 20px",
        }}
      >
        <IconButton onClick={() => setQuizView(false)} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
          {quiz?.title || "Quiz Details"}
        </Typography>

        {/* Only show info drawer button for teachers */}
        {!isStudent && (
          <Tooltip title="Quiz Information">
            <IconButton onClick={() => setInfoDrawerOpen(true)} size="small">
              <Newspaper style={{ color: "orange" }} />
            </IconButton>
          </Tooltip>
        )}

        <Chip
          label={quiz?.status}
          color={
            quiz?.status === "published"
              ? "success"
              : quiz?.status === "draft"
              ? "warning"
              : "default"
          }
          size="small"
          variant="outlined"
          sx={{ ml: 1 }}
        />
      </Box>

      {/* Questions Section */}
      <Box sx={{ mb: 2 }}>
        {/* For students, show StudentQuizView component */}
        {isStudent ? (
          <StudentQuizView
            quiz={quiz}
            totalQuestions={totalQuestions}
            results={quizResults}
          />
        ) : (
          /* For teachers, show the questions table */
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                padding: "0px 20px 0px 20px",
              }}
            >
              <Typography variant="subtitle1" fontWeight="600">
                Questions ({totalQuestions})
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setSelectedQuestion(null);
                  setOpenQuestionModal(true);
                }}
                size="small"
                sx={{
                  background:
                    "linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%)",
                  borderRadius: "4px",
                  textTransform: "none",
                  fontWeight: 500,
                }}
              >
                Add Question
              </Button>
            </Box>

            {/* Questions Table */}
            <QuestionsTable
              questions={questions}
              startIndex={startIndex}
              onRowClick={handleRowClick}
              onEditQuestion={handleEditQuestion}
              onDeleteQuestion={handleDeleteQuestion}
              isStudent={false}
            />

            {/* Pagination */}
            {totalPages > 1 && (
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 1 }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#2279F5",
                          color: "white",
                        },
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </>
        )}
      </Box>

      {/* Quiz Info Drawer - only for teachers */}
      {!isStudent && (
        <QuizInfoDrawer
          open={infoDrawerOpen}
          onClose={() => setInfoDrawerOpen(false)}
          quiz={quiz}
        />
      )}

      {/* Question Details Modal */}
      <QuestionDetailsModal
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        question={selectedQuestion}
        onEdit={handleEditQuestion}
      />

      {/* Question Create/Edit Modal */}
      {openQuestionModal && (
        <QuestionCreateModal
          open={openQuestionModal}
          handleClose={handleCloseModal}
          quizId={selectedQuiz.quiz_id}
          questionData={selectedQuestion}
          refreshQuestions={fetchQuizData}
        />
      )}

      {/* Student Answer Modal */}
      {openStudentAnswerModal && (
        <StudentAnswerModal
          open={openStudentAnswerModal}
          onClose={() => setOpenStudentAnswerModal(false)}
          question={selectedQuestion}
          quizId={selectedQuiz.quiz_id}
          onSubmit={(answer) => {
            // Handle student answer submission
            displayNotification("Answer submitted successfully");
            setOpenStudentAnswerModal(false);

            // In a real implementation, you'd update the quiz results here
            // For now, we'll just simulate completing the quiz
            if (quizResults) {
              setQuizResults({
                ...quizResults,
                completed: true,
                score: 8,
                totalScore: 10,
                percentageScore: 80,
                passedStatus: true,
                dateCompleted: new Date().toISOString(),
                attemptsRemaining: quizResults.attemptsRemaining - 1,
              });
            }
          }}
        />
      )}

      {/* Notification Snackbar */}
      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => setShowNotification(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationSeverity}
          sx={{ width: "100%" }}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default QuizView;