// QuizAttemptService.js
import { API_URL } from "../../../env";

/**
 * Service for managing quiz attempts and submissions
 */
class QuizAttemptService {
  /**
   * Create a new quiz attempt
   * @param {Object} data - The attempt data
   * @param {number} data.quiz_id - Quiz ID
   * @returns {Promise<Object>} The created attempt
   */
  static async createAttempt(data) {
    try {
      const formData = new URLSearchParams();
      formData.append("quiz_id", data.quiz_id);

      // Add other fields if provided
      if (data.start_time) formData.append("start_time", data.start_time);
      if (data.status) formData.append("status", data.status);

      const response = await fetch(`${API_URL}quiz_attempts/attempts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      // Parse response JSON even if it's an error response
      const responseData = await response.json();

      if (!response.ok) {
        // Handle the specific 500 error for maximum attempts reached
        if (response.status === 500 && responseData.detail && 
            responseData.detail.includes("Maximum attempts")) {
          throw new Error("MAX_ATTEMPTS_REACHED: " + responseData.detail);
        }
        
        // Handle other errors
        throw new Error(responseData.message || responseData.detail || "Failed to create attempt");
      }

      return responseData;
    } catch (error) {
      console.error("Error creating quiz attempt:", error);
      throw error;
    }
  }

  /**
   * Format the answer based on question type
   * @param {string} questionType - The type of question
   * @param {any} answer - The user's answer
   * @returns {string} Formatted answer JSON string
   */
  static formatAnswer(questionType, answer) {
    let formattedAnswer;

    if (questionType === "multiple_choice") {
      // Check if it's an array (multiple selection) or single value
      if (Array.isArray(answer)) {
        formattedAnswer = JSON.stringify({ selected_option: answer });
      } else {
        formattedAnswer = JSON.stringify({ selected_option: answer });
      }
    } else if (questionType === "true_false") {
      // Convert string 'true'/'false' to boolean if needed
      const boolValue =
        answer === "true" ? true : answer === "false" ? false : answer;
      formattedAnswer = JSON.stringify({ answer: boolValue });
    } else if (questionType === "short_answer") {
      formattedAnswer = JSON.stringify({ text: answer });
    } else {
      // Default fallback
      formattedAnswer = JSON.stringify(answer);
    }

    return formattedAnswer;
  }

  /**
   * Submit an answer for a quiz attempt
   * @param {number} attemptId - Attempt ID
   * @param {Object} data - The answer data
   * @param {number} data.question_id - Question ID
   * @param {any} data.user_answer - User's answer
   * @param {string} data.question_type - Type of question
   * @param {number} data.time_spent - Time spent on question
   * @returns {Promise<Object>} The submitted answer
   */
  static async submitAnswer(attemptId, data) {
    try {
      const formData = new URLSearchParams();
      formData.append("question_id", data.question_id);

      // Format the answer based on question type
      const formattedAnswer = this.formatAnswer(
        data.question_type,
        data.user_answer
      );
      formData.append("user_answer", formattedAnswer);

      formData.append("time_spent", data.time_spent);

      if (data.answered_at) formData.append("answered_at", data.answered_at);

      const response = await fetch(
        `${API_URL}quiz_attempts/attempts/${attemptId}/answers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.message || responseData.detail || "Failed to submit answer");
      }

      return responseData;
    } catch (error) {
      console.error("Error submitting answer:", error);
      throw error;
    }
  }

  // /**
  //  * Update the status of a quiz attempt
  //  * @param {number} attemptId - Attempt ID
  //  * @param {string} status - New status
  //  * @returns {Promise<Object>} The updated attempt
  //  */
  // static async updateAttemptStatus(attemptId, status) {
  //   try {
  //     const formData = new URLSearchParams();
  //     formData.append("status", status);

  //     const response = await fetch(
  //       `${API_URL}quiz_attempts/attempts/${attemptId}/submit`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: formData,
  //       }
  //     );

  //     const responseData = await response.json();
      
  //     if (!response.ok) {
  //       throw new Error(responseData.message || responseData.detail || "Failed to update attempt status");
  //     }

  //     return responseData;
  //   } catch (error) {
  //     console.error("Error updating attempt status:", error);
  //     throw error;
  //   }
  // }


/**
 * Submit the completed quiz attempt
 * @param {number} attemptId - Attempt ID
 * @param {Object} options - Optional parameters
 * @param {boolean} options.timeExpired - Whether submission is due to time expiration
 * @returns {Promise<Object>} The quiz attempt result
 */
static async submitQuiz(attemptId, options = {}) {
  try {
    // Create form data if time expired
    const formData = new URLSearchParams();
    
    // If time expired, add a flag to the request
    if (options.timeExpired) {
      formData.append("status", "completed");
      formData.append("time_expired", "true");
    }
    
    // Normal submission
    const response = await fetch(
      `${API_URL}quiz_attempts/attempts/${attemptId}/submit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: options.timeExpired ? formData : undefined,
      }
    );

    const responseData = await response.json();
    
    if (!response.ok) {
      throw new Error(responseData.message || responseData.detail || "Failed to submit quiz");
    }

    return responseData;
  } catch (error) {
    console.error("Error submitting quiz:", error);
    throw error;
  }
}

  /**
   * Get quiz attempt by ID
   * @param {number} attemptId - Attempt ID
   * @returns {Promise<Object>} The quiz attempt
   */
  static async getAttempt(attemptId) {
    try {
      const response = await fetch(
        `${API_URL}quiz_attempts/attempts/${attemptId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.message || responseData.detail || "Failed to get attempt");
      }

      return responseData;
    } catch (error) {
      console.error("Error getting quiz attempt:", error);
      throw error;
    }
  }
}

export default QuizAttemptService;