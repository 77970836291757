import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Chip,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  CheckCircle,
  Cancel,
  ArrowBack,
  Home,
  Refresh,
} from "@mui/icons-material";
import { API_URL } from "../../env";

const QuizResultPage = () => {
  const { quizId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Results can be passed via location state or fetched from API
  const [results, setResults] = useState(location.state?.results || null);
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(!location.state?.results);
  const [error, setError] = useState(null);
  
  console.log("Results data:", results);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // If results weren't passed via location state, fetch them
        if (!results) {
          const resultsResponse = await fetch(
            `${API_URL}quizz/${quizId}/results`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (!resultsResponse.ok) {
            throw new Error(
              `Failed to fetch results: ${resultsResponse.status}`
            );
          }

          const resultsData = await resultsResponse.json();
          setResults(resultsData);
        }

        // Fetch quiz details
        const quizResponse = await fetch(`${API_URL}quizz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!quizResponse.ok) {
          throw new Error(`Failed to fetch quiz: ${quizResponse.status}`);
        }

        const quizData = await quizResponse.json();
        setQuiz(quizData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [quizId, results]);

  const handleRetakeQuiz = () => {
    navigate(`/take-quiz/${quizId}`);
  };

  const handleBackToQuizzes = () => {
    navigate("/home"); // Adjust this path as needed
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
        }}
      >
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading results...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        sx={{ 
          p: 3, 
          maxWidth: "600px", 
          mx: "auto", 
          mt: 4,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
          minHeight: "calc(100vh - 100px)"
        }}
      >
        <Paper elevation={3} sx={{ p: 3, borderRadius: "12px" }}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
          <Button
            variant="contained"
            startIcon={<Home />}
            onClick={handleBackToQuizzes}
            sx={{ 
              mt: 2,
              background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
              borderRadius: "8px"
            }}
          >
            Back to Quizzes
          </Button>
        </Paper>
      </Box>
    );
  }

  if (!results || !quiz) {
    return (
      <Box sx={{ 
        p: 3, 
        maxWidth: "600px", 
        mx: "auto", 
        mt: 4,
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
        minHeight: "calc(100vh - 100px)"
      }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: "12px" }}>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Results not found. You may need to take the quiz first.
          </Alert>
          <Button
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={handleBackToQuizzes}
            sx={{ 
              mt: 2,
              background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
              borderRadius: "8px"
            }}
          >
            Back to Quizzes
          </Button>
        </Paper>
      </Box>
    );
  }

  // Access results data correctly - using API response field names
  const isPassed = results.is_passed;
  const score = results.score || 0;
  const totalScore = results.total_score || 0;
  const correctAnswers = results.correct_answers || 0;
  const incorrectAnswers = results.incorrect_answers || 0;
  const totalQuestions = correctAnswers + incorrectAnswers;
  const attemptsRemaining = results.attempts_remaining !== undefined ? results.attempts_remaining : 0;
  const totalAttempts = results.total_attempts !== undefined ? results.total_attempts : 0;
  const passingScore = quiz.passing_score || 0;
  const timeTaken = results.timeTaken || `${Math.floor((results.time_spent || 0) / 60)}m ${(results.time_spent || 0) % 60}s`;

  // Calculate percentage for progress bar
  const percentageScore = score; // API already returns as percentage
  
  return (
    <Box 
      sx={{ 
        p: 3, 
        maxWidth: "800px", 
        mx: "auto",
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
        minHeight: "calc(100vh - 100px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: "12px",
          borderTop: `8px solid ${isPassed ? "#4CAF50" : "#FF9800"}`,
          background: `linear-gradient(to bottom, ${
            isPassed ? "rgba(76, 175, 80, 0.05)" : "rgba(255, 152, 0, 0.05)"
          } 0%, rgba(255, 255, 255, 1) 100%)`,
          width: "100%",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          {isPassed ? (
            <CheckCircle 
              sx={{ 
                fontSize: 80, 
                color: "#4CAF50",
                filter: "drop-shadow(0 4px 6px rgba(76, 175, 80, 0.3))"
              }} 
            />
          ) : (
            <Cancel 
              sx={{ 
                fontSize: 80, 
                color: "#FF9800",
                filter: "drop-shadow(0 4px 6px rgba(255, 152, 0, 0.3))"
              }} 
            />
          )}
        </Box>

        <Typography 
          variant="h4" 
          align="center" 
          sx={{ 
            mb: 1,
            fontWeight: "bold",
            color: isPassed ? "#2E7D32" : "#E65100"
          }}
        >
          {isPassed ? "Quiz Passed!" : "Quiz Completed"}
        </Typography>

        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          sx={{ mb: 3 }}
        >
          {isPassed
            ? "Congratulations! You've successfully passed this quiz."
            : "Keep learning! You didn't meet the minimum passing score."}
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              textAlign: "center", 
              p: 2, 
              background: isPassed ? "rgba(76, 175, 80, 0.05)" : "rgba(255, 152, 0, 0.05)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Your Score
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={isPassed ? "#4CAF50" : "#FF9800"}
              >
                {score}%
              </Typography>
              <Typography variant="body1">
                {totalScore} points
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              textAlign: "center", 
              p: 2,
              background: "rgba(34, 121, 245, 0.05)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Passing Score
              </Typography>
              <Typography variant="h4" fontWeight="bold" color="#2279F5">
                {passingScore}%
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                height: "10px",
                width: "100%",
                bgcolor: "rgba(0,0,0,0.05)",
                borderRadius: "5px",
                overflow: "hidden",
                mt: 2,
                boxShadow: "inset 0 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: `${percentageScore}%`,
                  bgcolor: isPassed ? "#4CAF50" : "#FF9800",
                  position: "relative",
                  transition: "width 1s ease-in-out",
                  boxShadow: `0 0 5px ${isPassed ? "rgba(76, 175, 80, 0.5)" : "rgba(255, 152, 0, 0.5)"}`,
                }}
              >
                {passingScore < 100 && (
                  <Box
                    sx={{
                      position: "absolute",
                      height: "200%",
                      width: "2px",
                      bgcolor: "#2279F5",
                      top: "-50%",
                      left: `${passingScore}%`,
                      zIndex: 2,
                      boxShadow: "0 0 5px rgba(34, 121, 245, 0.7)",
                    }}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6} sm={3}>
            <Box sx={{ 
              textAlign: "center",
              p: 2,
              height: "100%",
              background: "rgba(0, 0, 0, 0.02)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Time Taken
              </Typography>
              <Typography variant="body1" fontWeight="medium">
                {timeTaken}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Box sx={{ 
              textAlign: "center",
              p: 2,
              height: "100%",
              background: "rgba(0, 0, 0, 0.02)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Questions
              </Typography>
              <Typography variant="body1" fontWeight="medium">
                {totalQuestions}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Box sx={{ 
              textAlign: "center",
              p: 2,
              height: "100%",
              background: "rgba(76, 175, 80, 0.05)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Correct Answers
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="#4CAF50">
                {correctAnswers}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Box sx={{ 
              textAlign: "center",
              p: 2,
              height: "100%",
              background: "rgba(255, 87, 34, 0.05)",
              borderRadius: "8px"
            }}>
              <Typography variant="body2" color="text.secondary">
                Incorrect Answers
              </Typography>
              <Typography variant="body1" fontWeight="medium" color="#FF5722">
                {incorrectAnswers}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={handleBackToQuizzes}
            sx={{
              borderRadius: "8px",
              borderColor: "#2279F5",
              color: "#2279F5",
              "&:hover": {
                borderColor: "#166088",
                bgcolor: "rgba(34, 121, 245, 0.05)",
              },
              transition: "all 0.2s ease",
              px: 3,
            }}
          >
            Back to Quizzes
          </Button>

          {attemptsRemaining > 0 && (
            <Button
              variant="contained"
              startIcon={<Refresh />}
              onClick={handleRetakeQuiz}
              sx={{
                borderRadius: "8px",
                background: "linear-gradient(135deg, #2279F5 0%, #00D2FE 100%)",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                "&:hover": {
                  background: "linear-gradient(135deg, #1e6ad4 0%, #00bfe8 100%)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                },
                transition: "all 0.2s ease",
                px: 3,
              }}
            >
              Retake Quiz
            </Button>
          )}
        </Box>

        {attemptsRemaining !== undefined && (
          <Box sx={{ 
            mt: 2, 
            p: 1, 
            bgcolor: "rgba(0, 0, 0, 0.03)", 
            borderRadius: "4px", 
            textAlign: "center" 
          }}>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Attempts remaining: <strong>{attemptsRemaining}</strong> of <strong>{totalAttempts}</strong>
            </Typography>
          </Box>
        )}
      </Paper>

     
    </Box>
  );
};

export default QuizResultPage;