import React, { useEffect, useState } from "react";
import "./MyCaseBox.css";
import {
  ArrowBack,
  Delete,
  DeleteOutline,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  Edit,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { API_URL } from "../../env";
import { Copy, CopyCheck, Info, QrCode } from "lucide-react";
import SelectSlidesModal from "./SelectSlidesModal";
import MicroscopeSlideViewer from "./../../test/MicroscopeSlideViewer";

const MyCaseBoxImages = ({ caseData, caseBoxId, onBack }) => {
  // State management
  const [selectSlidesModalOpen, setSelectSlidesModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSlides, setSelectedSlides] = useState({});
  const [openWorkspace, setOpenWorkspace] = useState(false);
  const [currentSlideId, setCurrentSlideId] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [filter, setFilter] = useState("");
  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  // Fetch case box images
  useEffect(() => {
    const fetchCaseBoxImages = async () => {
      setIsLoading(true);
      try {
        let url = `${API_URL}case_box_images/casebox/${caseBoxId}?page=${page}&limit=5`;

        // Add search params if they exist
        if (searchTerm) url += `&search=${encodeURIComponent(searchTerm)}`;
        if (filter) url += `&filter=${encodeURIComponent(filter)}`;
        if (startDate) url += `&start_date=${encodeURIComponent(startDate)}`;
        if (endDate) url += `&end_date=${encodeURIComponent(endDate)}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setSlides(data.images || []);
        setTotalPages(data.total_pages || 1);
      } catch (error) {
        console.error("Error fetching case box images:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch case box images. Please try again.",
        });
        setSlides([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (caseBoxId) {
      fetchCaseBoxImages();
    }
  }, [caseBoxId, page, refresh, searchTerm, filter, startDate, endDate]);

  const handleRowDoubleClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  const handleViewDetailsClick = (slideId, imageUrl) => {
    setCurrentSlideId(slideId);
    setCurrentImageUrl(imageUrl);
    setOpenWorkspace(true);
  };

  // Handle slide selection
  const handleSelectAll = (e) => {
    const newSelectedSlides = {};
    if (e.target.checked && slides) {
      slides.forEach((slide) => {
        newSelectedSlides[slide.SlideId] = true;
      });
    }
    setSelectedSlides(newSelectedSlides);
  };

  const handleSelectSlide = (SlideId) => {
    setSelectedSlides((prevSelected) => ({
      ...prevSelected,
      [SlideId]: !prevSelected[SlideId],
    }));
  };

  const isSlideSelected = (SlideId) => !!selectedSlides[SlideId];

  const isAllSelected =
    slides &&
    slides.length > 0 &&
    Object.keys(selectedSlides).length === slides.length;

  // Handle pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle modal actions
  const handleModalClose = () => {
    setSelectSlidesModalOpen(false);
  };

  const handleModalConfirm = () => {
    setRefresh((prev) => !prev);
  };

  const handleSlidesSelected = (selectedSlides) => {
    console.log("Selected slides:", selectedSlides);
  };

  // Handle delete
  const handleDeleteClick = () => {
    const selectedSlideIds = Object.keys(selectedSlides).filter(
      (key) => selectedSlides[key]
    );
    if (selectedSlideIds.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No Slides Selected",
        text: "Please select slides to delete.",
      });
      return;
    }
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const selectedSlideIds = Object.keys(selectedSlides).filter(
      (key) => selectedSlides[key]
    );

    try {
      const response = await fetch(`${API_URL}case_box_images/bulk_delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          slideIds: selectedSlideIds,
          caseBoxId: caseBoxId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete slides");
      }

      setDeleteConfirmOpen(false);
      setSelectedSlides({});
      setRefresh((prev) => !prev);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Selected slides have been deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting slides:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete slides. Please try again.",
      });
    }
  };

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleString();
    } catch (error) {
      return dateString;
    }
  };

  return (
    <>
      {openWorkspace ? (
        <MicroscopeSlideViewer
          currentSlideId={currentSlideId}
          openWorkspace={openWorkspace}
          setOpenWorkspace={setOpenWorkspace}
          currentImageUrl={currentImageUrl}
        />
      ) : (
        <div>
          <div className="casebox-container">
            {/* Header */}
            <div
              className="casebox-header"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="casebox-header-title"
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <div style={{ cursor: "pointer" }} onClick={handleBackClick}>
                  <ArrowBack />
                </div>
                <span
                  style={{
                    fontSize: "18px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  {caseData?.CaseBoxName || "Case Box Images"}
                </span>
              </div>

              {/* Filters and Actions */}
              <div className="casebox-filter-box">
                <div className="casebox-search">
                  <input
                    style={{ border: "none" }}
                    className="casebox-search-input"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div>
                    <Search />
                  </div>
                </div>

                <div>
                  <select
                    className="specialization"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="">Filter</option>
                    <option value="specialization1">Specialization 1</option>
                    <option value="specialization2">Specialization 2</option>
                    <option value="specialization3">Specialization 3</option>
                  </select>
                </div>

                <div className="casebox-date-filter">
                  From
                  <input
                    className="casebox-date-input"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="casebox-date-filter-to">
                  To
                  <input
                    className="casebox-date-input"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>

                <div
                  style={{ width: 86 }}
                  className="casebox-create-btn"
                  onClick={() => setSelectSlidesModalOpen(true)}
                >
                  Select Slides
                </div>

                <div className="casebox-delete-btn" onClick={handleDeleteClick}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 40,
                    }}
                  >
                    <Delete fontSize="12px" />
                  </div>
                  <div>Delete</div>
                </div>
              </div>
            </div>

            {/* Table */}
            <div className="casebox-table-container">
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="200px"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ padding: 0, borderBottom: "none" }}
                        className="casebox-checkbox-cell"
                      >
                        <Checkbox
                          className="casebox-checkbox"
                          indeterminate={
                            Object.keys(selectedSlides).length > 0 &&
                            !isAllSelected
                          }
                          checked={isAllSelected}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell className="casebox-table-head">
                        Thumbnail
                      </TableCell>
                      <TableCell className="casebox-table-head">Name</TableCell>
                      <TableCell className="casebox-table-head">
                        Added At
                      </TableCell>
                      <TableCell className="casebox-table-head">
                        Added By
                      </TableCell>
                      
                      <TableCell
                        style={{ textAlign: "end", borderBottom: "none" }}
                        className="casebox-table-head-actions"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slides &&
                      slides.map((row) => (
                        <React.Fragment key={row.CaseBoxImageId}>
                          <TableRow
                            onDoubleClick={() =>
                              handleRowDoubleClick(row.SlideId, row.SlideUrl)
                            }
                            className="casebox-table-row"
                          >
                            <TableCell
                              style={{
                                borderTopLeftRadius: 12,
                                borderBottomLeftRadius: 12,
                              }}
                              className="casebox-table-cell casebox-cell-checkbox"
                            >
                              <Checkbox
                                className="casebox-checkbox"
                                checked={isSlideSelected(row.SlideId)}
                                onChange={() => handleSelectSlide(row.SlideId)}
                              />
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              <img
                                className="casebox-thumbnail"
                                src={row.Thumbnail}
                                alt={row.SlideName}
                              />
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {row.SlideName}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {formatDate(row.AddedAt)}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {row.AddedByName}
                            </TableCell>
                            {/* <TableCell className="casebox-table-cell">
                              {row.Section}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {row.Diagnosis}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {row.Stain}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              {row.Folder}
                            </TableCell>
                            <TableCell className="casebox-table-cell">
                              <div className="tags">
                                <div className="workspace-tag">
                                  {row.SlideType}
                                </div>
                              </div>
                            </TableCell> */}
                            <TableCell
                              style={{
                                borderTopRightRadius: 12,
                                borderBottomRightRadius: 12,
                                textAlign: "end",
                              }}
                              className="casebox-table-cell casebox-actions-cell"
                            >
                              <IconButton
                                onClick={() =>
                                  handleViewDetailsClick(
                                    row.SlideId,
                                    row.SlideUrl
                                  )
                                }
                                size="small"
                              >
                                <RemoveRedEyeOutlined className="icon-view" />
                              </IconButton>
                              <IconButton size="small">
                                <Edit className="icon-edit" />
                              </IconButton>

                              <IconButton size="small">
                                <DeleteOutline className="icon-delete" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <div className="casebox-row-spacer"></div>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              )}
            </div>

            {/* Pagination */}
            <div className="casebox-pagination-container">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>
          </div>

          {/* Modals */}
          {selectSlidesModalOpen && (
            <SelectSlidesModal
              open={selectSlidesModalOpen}
              onClose={handleModalClose}
              onSelect={handleSlidesSelected}
              onConfirm={handleModalConfirm}
              caseBoxId={caseBoxId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MyCaseBoxImages;
