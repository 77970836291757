import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { API_URL } from "../../env";

const statusOptions = [
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
  { value: "archived", label: "Archived" },
];

const QuizCreateModal = ({ open, handleClose, refreshQuizzes, quizData }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    passing_score: 70,
    time_limit: 0,
    shuffle_questions: false,
    show_results: true,
    max_attempts: 1,
    status: "draft",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (quizData) {
      setFormData({
        title: quizData.title || "",
        description: quizData.description || "",
        passing_score: quizData.passing_score || 70,
        time_limit: quizData.time_limit || 0,
        shuffle_questions: quizData.shuffle_questions || false,
        show_results: quizData.show_results || true,
        max_attempts: quizData.max_attempts || 1,
        status: quizData.status || "draft",
      });
    }
  }, [quizData]);

  const handleInputChange = (field) => (event) => {
    const value =
      field === "shuffle_questions" || field === "show_results"
        ? event.target.checked
        : event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // Validate required fields
      const requiredFields = ["title", "passing_score"];
      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        setError(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
        setLoading(false);
        return;
      }

      const url = quizData
        ? `${API_URL}quizz/${quizData.quiz_id}`
        : `${API_URL}quizz`;
      
      const method = quizData ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || response.statusText}`);
      }

      // First close the modal
      handleClose();
      
      // Then refresh the quiz list - important to call this separately and after handleClose
      refreshQuizzes();
      
    } catch (err) {
      console.error("Error handling quiz:", err);
      setError(
        quizData
          ? "Failed to update quiz. Please try again."
          : "Failed to create quiz. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const renderTextField = (
    label,
    field,
    select = false,
    options = [],
    type = "text"
  ) => (
    <Grid item xs={6}>
      <label className="label">{label}*</label>
      <TextField
        fullWidth
        type={type}
        value={formData[field]}
        onChange={handleInputChange(field)}
        variant="outlined"
        sx={textFieldStyles}
        select={select}
      >
        {select &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="courses-box" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {quizData ? "Edit Quiz" : "Create New Quiz"}
            </Typography>
            <Box noValidate autoComplete="off">
              <Grid container spacing={2}>
                {renderTextField("Title", "title")}
                {renderTextField("Status", "status", true, statusOptions)}
                <Grid item xs={12}>
                  <label
                    className="label"
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      display: "block",
                    }}
                  >
                    Description*
                  </label>
                  <TextField
                    style={{ background: "white" }}
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange("description")}
                    variant="outlined"
                    multiline
                    rows={2}
                  />
                </Grid>
                {renderTextField("Passing Score (%)", "passing_score", false, [], "number")}
                {renderTextField("Time Limit (mins)", "time_limit", false, [], "number")}
                {renderTextField("Max Attempts", "max_attempts", false, [], "number")}
                
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.shuffle_questions}
                        onChange={handleInputChange("shuffle_questions")}
                        color="primary"
                      />
                    }
                    label="Shuffle Questions"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.show_results}
                        onChange={handleInputChange("show_results")}
                        color="primary"
                      />
                    }
                    label="Show Results"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>
              </Grid>
            </Box>
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit}
                style={{
                  opacity: loading ? 0.7 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                {loading ? "Submitting..." : quizData ? "Update" : "Submit"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default QuizCreateModal;