import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Button,
  Chip,
  CircularProgress,
  Pagination,
  Dialog,
  IconButton,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Alert,
  TextField,
  FormControl,
  Select,
  InputLabel
} from "@mui/material";
import {
  AccessTime,
  CalendarToday,
  CheckCircle,
  CancelOutlined,
  PictureAsPdf,
  VideoLibrary,
  Image,
  InsertDriveFile,
  Close,
  Edit,
  Delete,
  MoreVert,
  ArrowBack
} from "@mui/icons-material";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { API_URL } from "../../../env";
import { SearchIcon } from "lucide-react";

// Import ModuleContentsModal for create/edit functionality
import ModuleContentsModal from "../ModuleContents/ModuleContentModal"; // Adjust the import path as needed

const ModuleContentsPage = ({
  selectedCourseId,
  openModalContents,
  setOpenModalContents,
  selectedModuleId,
  setRefresh,
  refresh,
  openEditModal: initialOpenEditModal,
  setOpenEditModal: parentSetOpenEditModal,
}) => {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [selectedContent, setSelectedContent] = useState(null);
  const [detailViewOpen, setDetailViewOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeContentId, setActiveContentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  
  // Local state for create and edit modals
  const [openModuleContentsModal, setOpenModuleContentsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(initialOpenEditModal || false);

  useEffect(() => {
    fetchModuleContents(currentPage);
  }, [selectedModuleId, currentPage, pageSize, searchTerm, refresh]);

  // Sync with parent edit modal state if provided
  useEffect(() => {
    if (typeof initialOpenEditModal !== 'undefined') {
      setOpenEditModal(initialOpenEditModal);
    }
  }, [initialOpenEditModal]);

  // Sync back to parent when local state changes
  useEffect(() => {
    if (parentSetOpenEditModal && openEditModal !== initialOpenEditModal) {
      parentSetOpenEditModal(openEditModal);
    }
  }, [openEditModal, initialOpenEditModal, parentSetOpenEditModal]);

  const fetchModuleContents = async (page) => {
    setLoading(true);
    setError(null);

    try {
      // Use pagination parameters and search term if available
      const url = new URL(`${API_URL}module/content/${selectedModuleId}`);
      url.searchParams.append('page', page);
      url.searchParams.append('page_size', pageSize);
      
      if (searchTerm.trim()) {
        url.searchParams.append('search', searchTerm.trim());
      }

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch module contents (${response.status})`);
      }

      const data = await response.json();
      setContents(data.contents);
      setCurrentPage(data.current_page);
      setTotalPages(data.total_pages);
      setTotalItems(data.total_items || data.contents.length);
    } catch (error) {
      console.error("Error fetching module contents:", error);
      setError(error.message || "Failed to load module contents");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContent = async () => {
    if (!contentToDelete) return;
    
    try {
      const response = await fetch(`${API_URL}module/content/${contentToDelete.ContentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete content (${response.status})`);
      }

      setSnackbar({
        open: true,
        message: 'Content deleted successfully',
        severity: 'success'
      });
      
      // Refresh content list
      fetchModuleContents(currentPage);
      
      // Update parent component if needed
      if (setRefresh) {
        setRefresh(prev => !prev);
      }
    } catch (error) {
      console.error("Error deleting content:", error);
      setSnackbar({
        open: true,
        message: `Failed to delete content: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setConfirmDeleteOpen(false);
      setContentToDelete(null);
    }
  };

  const handleEditContent = (content, e) => {
    if (e) e.stopPropagation();
    setSelectedContent(content);
    // Open the edit modal
    setOpenEditModal(true);
    handleMenuClose();
  };

  const handleMenuOpen = (event, content) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setActiveContentId(content.ContentId);
    setSelectedContent(content);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveContentId(null);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setContentToDelete(selectedContent);
    setConfirmDeleteOpen(true);
    handleMenuClose();
  };

  const formatDateToUserTimeZone = (utcDateString) => {
    if (!utcDateString) return "";
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zonedDate = toZonedTime(utcDateString, userTimeZone);
      return format(zonedDate, "MMMM do, yyyy h:mm:ss a");
    } catch (error) {
      console.error("Error formatting date:", error);
      return utcDateString;
    }
  };

  const getContentTypeIcon = (contentType) => {
    const type = contentType?.toLowerCase() || "";
    if (type === "pdf") return <PictureAsPdf color="error" />;
    if (["video", "mp4", "mov", "avi"].includes(type)) return <VideoLibrary color="primary" />;
    if (["image", "jpg", "jpeg", "png", "gif"].includes(type)) return <Image color="success" />;
    return <InsertDriveFile color="action" />;
  };

  const handleContentClick = (content) => {
    setSelectedContent(content);
    setDetailViewOpen(true);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePreview = (content, e) => {
    e.stopPropagation();
    setSelectedContent(content);
    setPreviewOpen(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const renderContentPreview = () => {
    if (!selectedContent) return null;

    const fileUrl = `${selectedContent.FileUrl}`;
    const contentType = selectedContent.ContentType?.toLowerCase() || "";
    
    if (contentType === "pdf") {
      return (
        <iframe
          src={`https://path-image.s3.amazonaws.com/${fileUrl}#view=FitH`}
          title={selectedContent.Title}
          width="100%"
          height="600px"
          style={{ border: "none" }}
        />
      );
    } else if (["jpg", "jpeg", "png", "gif", "image"].includes(contentType)) {
      return (
        <img
          src={`https://path-image.s3.amazonaws.com/${fileUrl}`}
          alt={selectedContent.Title}
          style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }}
        />
      );
    } else if (["video", "mp4", "mov", "avi"].includes(contentType)) {
      return (
        <video controls width="100%" height="auto">
          <source src={`https://path-image.s3.amazonaws.com/${fileUrl}`} type={`video/${contentType === "video" ? "mp4" : contentType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" p={3}>
          <InsertDriveFile sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
          <Typography variant="body1">
            This file type cannot be previewed. Please download to view.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={`https://path-image.s3.amazonaws.com/${fileUrl}`}
            target="_blank"
            sx={{ mt: 2 }}
          >
            Download File
          </Button>
        </Box>
      );
    }
  };

  return (
    <div className="worklist-container">
      <div className="courses-header">
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setOpenModalContents && setOpenModalContents(false)}
        >
          <ArrowBack />
          <div style={{ width: 300, marginLeft: 20 }} className="header-title">
            Module Contents
          </div>
        </div>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            size="small"
            placeholder="Search modules contents..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <SearchIcon size={18} style={{ color: "gray", marginRight: 8 }} />,
            }}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
          <div
            className="courses-case"
            onClick={() => setOpenModuleContentsModal(true)}
          >
            Create Module
          </div>
        </Box>
      </div>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="300px">
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
          <Button variant="outlined" onClick={() => fetchModuleContents(currentPage)}>
            Retry
          </Button>
        </Box>
      ) : contents.length > 0 ? (
        <>
          <Grid style={{padding:20}} sx={{}} container spacing={3}>
            {contents.map((content) => (
              <Grid item xs={12} sm={6} md={3} key={content.ContentId}>
                <Card 
                  sx={{ 
                    height: "100%", 
                    display: "flex", 
                    flexDirection: "column",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: 4
                    },
                    position: "relative"
                  }}
                >
                  {/* Menu icon in top right corner */}
                  <IconButton
                    size="small"
                    sx={{ 
                      position: "absolute", 
                      top: 8, 
                      right: 8, 
                      zIndex: 2,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      }
                    }}
                    onClick={(e) => handleMenuOpen(e, content)}
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>

                  <CardActionArea 
                    sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch" }}
                    onClick={() => handleContentClick(content)}
                  >
                    <CardMedia
                      component="div"
                      sx={{
                        height: 140,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "action.hover"
                      }}
                    >
                      {content.ThumbnailUrl ? (
                        <img
                          src={`${API_URL}${content.ThumbnailUrl}`}
                          alt={content.Title}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      ) : (
                        getContentTypeIcon(content.ContentType)
                      )}
                    </CardMedia>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" noWrap>
                        {content.Title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2, display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden" }}>
                        {content.Description || "No description available"}
                      </Typography>
                      <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                        <Chip 
                          icon={<AccessTime fontSize="small" />} 
                          label={`${content.Duration || 0} min`} 
                          size="small" 
                          variant="outlined"
                        />
                        <Chip 
                          icon={content.IsRequired ? <CheckCircle fontSize="small" /> : <CancelOutlined fontSize="small" />} 
                          label={content.IsRequired ? "Required" : "Optional"}
                          color={content.IsRequired ? "primary" : "default"}
                          size="small"
                          variant="outlined"
                        />
                      </Box>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Added: {formatDateToUserTimeZone(content.CreatedAt).split(',')[0]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  
                  {/* Preview button only - removed duplicate edit/delete buttons */}
                  <Box p={1} bgcolor="background.paper" display="flex" justifyContent="center">
                    <Button 
                      variant="contained" 
                      size="small"
                      onClick={(e) => handlePreview(content, e)}
                      sx={{ width: '100%' }}
                    >
                      Preview
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Updated pagination section to match CoursesList style */}
          {totalPages > 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <Typography>No content available for this module</Typography>
        </Box>
      )}

      {/* Content Preview Modal */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: 3,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
          <Typography variant="h6" component="div">
            {selectedContent?.Title}
          </Typography>
          <IconButton onClick={() => setPreviewOpen(false)} size="small">
            <Close />
          </IconButton>
        </Box>
        
        <Divider />
        
        <Box sx={{ p: 2 }}>
          {renderContentPreview()}
        </Box>
      </Dialog>

      {/* Menu for content actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(e) => handleEditContent(selectedContent, e)}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Content</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete Content</ListItemText>
        </MenuItem>
      </Menu>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Deletion
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Are you sure you want to delete "{contentToDelete?.Title}"? This action cannot be undone.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setConfirmDeleteOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteContent} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Create Module Modal */}
      {openModuleContentsModal && (
        <ModuleContentsModal
          selectedModuleId={selectedModuleId}
          selectedCourseId={selectedCourseId}
          open={openModuleContentsModal}
          setOpen={setOpenModuleContentsModal}
          onSuccess={() => {
            if (setRefresh) setRefresh(prev => !prev);
            fetchModuleContents(currentPage);
          }}
        />
      )}

      {/* Edit Module Modal */}
      {openEditModal && selectedContent && (
        <ModuleContentsModal
          selectedModuleId={selectedModuleId}
          selectedCourseId={selectedCourseId}
          open={openEditModal}
          setOpen={setOpenEditModal}
          moduleData={selectedContent}
          onSuccess={() => {
            if (setRefresh) setRefresh(prev => !prev);
            fetchModuleContents(currentPage);
          }}
        />
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ModuleContentsPage;