import React from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
} from "@mui/material";

const MultipleChoiceQuestion = ({ question, selectedOption, onChange }) => {
  // Parse JSON safely
  const safelyParseJSON = (jsonString, fallback = {}) => {
    try {
      return typeof jsonString === "string"
        ? JSON.parse(jsonString)
        : jsonString;
    } catch (error) {
      console.error("JSON parsing error:", error);
      return fallback;
    }
  };

  // Get the options from the question
  const options = safelyParseJSON(question.options, {});

  // Handle option selection
  const handleOptionChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <RadioGroup value={selectedOption || ""} onChange={handleOptionChange}>
        {Object.entries(options).map(([key, value]) => (
          <Paper
            key={key}
            elevation={selectedOption === key ? 3 : 1}
            sx={{
              p: 2,
              mb: 2,
              borderRadius: "8px",
              border:
                selectedOption === key
                  ? "2px solid #4a6fa5"
                  : "1px solid #e0e0e0",
              backgroundColor:
                selectedOption === key ? "rgba(74, 111, 165, 0.1)" : "white",
              transition: "all 0.2s ease",
            }}
          >
            <FormControlLabel
              value={key}
              control={
                <Radio
                  sx={{
                    color: "#4a6fa5",
                    "&.Mui-checked": {
                      color: "#4a6fa5",
                    },
                  }}
                />
              }
              label={
                <Typography variant="body1">
                  <strong>{key}.</strong> {value}
                </Typography>
              }
              sx={{ width: "100%", margin: 0 }}
            />
          </Paper>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default MultipleChoiceQuestion;
