import React, { useState } from "react";
import { Link, Linkedin, QrCode, X, Download } from "lucide-react";
import { Facebook, WhatsApp } from "@mui/icons-material";
import { API_URL } from "../../env";

const ShareModal = ({ shareModal, onClose, slideId }) => {
  const [visibility, setVisibility] = useState("unlisted");
  const [selectedOption, setSelectedOption] = useState("link");
  const [password, setPassword] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [emails, setEmails] = useState("");
  const [shareLink, setShareLink] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");

  if (!shareModal) return null;

  const handleShare = async () => {
    try {
      const submissionData = {
        slide_id: slideId,
        password: visibility === "unlisted" ? password : "",
        shared_emails:
          selectedOption === "email"
            ? emails.split(",").map((email) => email.trim())
            : [],
        expiry_date: expirationDate,
        share_type: selectedOption,
        visibility: visibility,
        is_edit: true,
      };

      const response = await fetch(`${API_URL}share/share`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();

      if (data.generated_url) {
        setShareLink(data.generated_url);
        if (data.qr_code) {
          setQrCodeData(data.qr_code);
        }

        switch (selectedOption) {
          case "whatsapp":
            window.open(
              `https://wa.me/?text=${encodeURIComponent(data.generated_url)}`,
              "_blank"
            );
            break;
          case "facebook":
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                data.generated_url
              )}`,
              "_blank"
            );
            break;
          case "twitter":
            window.open(
              `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                data.generated_url
              )}`,
              "_blank"
            );
            break;
          case "linkedin":
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                data.generated_url
              )}`,
              "_blank"
            );
            break;
          case "link":
            navigator.clipboard.writeText(data.generated_url);
            setIsLinkCopied(true);
            setTimeout(() => setIsLinkCopied(false), 2000);
            break;
          case "email":
            window.location.href = `mailto:${emails}?subject=Shared Case&body=${encodeURIComponent(
              data.generated_url
            )}`;
            break;
        }
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 2000);
  };

  const handleDownloadQR = () => {
    if (qrCodeData) {
      const link = document.createElement("a");
      link.href = qrCodeData;
      link.download = "qr-code.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderContent = () => {
    if (selectedOption === "qrcode" && qrCodeData) {
      return (
        <div className="qr-code-container">
          <img src={qrCodeData} alt="QR Code" className="qr-code-image" />
          <button
            onClick={handleDownloadQR}
            className="flex items-center gap-2 mx-auto px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200"
          >
            <Download size={16} />
            Download QR Code
          </button>
        </div>
      );
    }

    if (selectedOption === "link" && shareLink) {
      return (
        <div className="share-link-container">
          <input
            type="text"
            value={shareLink}
            readOnly
            className="share-link-input"
          />
          <button onClick={handleCopyLink} className="copy-button">
            {isLinkCopied ? "Copied!" : "Copy"}
          </button>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className="share-modal-overlay"
      onClick={(e) => {
        if (e.target.className === "share-modal-overlay") onClose();
      }}
    >
      <div className="share-modal">
        <button className="close-button" onClick={onClose}>
          ×
        </button>

        <h2 className="modal-title">Share</h2>
        <div className="visibility-container">
          <div className="visibility-header">
            <span>Visibility</span>
            <span className="info-icon">ⓘ</span>
          </div>
          <div className="visibility-options">
            <label className="visibility-option">
              <input
                type="radio"
                name="visibility"
                value="public"
                checked={visibility === "public"}
                onChange={(e) => setVisibility(e.target.value)}
              />
              <span className="visibility-radio"></span>
              <span className="visibility-text">Public</span>
            </label>
            <label className="visibility-option">
              <input
                type="radio"
                name="visibility"
                value="unlisted"
                checked={visibility === "unlisted"}
                onChange={(e) => setVisibility(e.target.value)}
              />
              <span className="visibility-radio"></span>
              <span className="visibility-text">Private</span>
            </label>
          </div>
        </div>

        <div className="share-options">
          <button
            className={`share-option ${
              selectedOption === "link" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("link")}
            data-option="link"
          >
            <span className="option-icon">
              <Link />
            </span>
            <span className="option-label">Link</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "qrcode" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("qrcode")}
            data-option="qrcode"
          >
            <span className="option-icon">
              <QrCode />
            </span>
            <span className="option-label">QR Code</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "email" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("email")}
            data-option="email"
          >
            <span className="option-icon">✉️</span>
            <span className="option-label">Email</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "facebook" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("facebook")}
            data-option="facebook"
          >
            <span className="option-icon fb-icon">
              <Facebook />
            </span>
            <span className="option-label">Facebook</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "twitter" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("twitter")}
            data-option="twitter"
          >
            <span className="option-icon">
              <X />
            </span>
            <span className="option-label">X(Twitter)</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "whatsapp" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("whatsapp")}
            data-option="whatsapp"
          >
            <span className="option-icon">
              <WhatsApp />
            </span>
            <span className="option-label">WhatsApp</span>
          </button>
          <button
            className={`share-option ${
              selectedOption === "linkedin" ? "selected" : ""
            }`}
            onClick={() => setSelectedOption("linkedin")}
            data-option="linkedin"
          >
            <span className="option-icon linkedin-icon">
              <Linkedin />
            </span>
            <span className="option-label">LinkedIn</span>
          </button>
        </div>

        {selectedOption === "email" && (
          <div className="input-group">
            <label>Email Addresses</label>
            <input
              type="text"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              placeholder="Enter emails separated by commas"
              className="input-fieldDate"
            />
          </div>
        )}

        {renderContent()}

        <div className="pass-">
          {visibility === "unlisted" && (
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="input-fieldDate"
              />
            </div>
          )}
          <div className="input-group">
            <label>Expiration Date</label>
            <input
              type="datetime-local"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
              className="input-fieldDate"
            />
          </div>
        </div>

        <div className="modal-actions">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="share-button" onClick={handleShare}>
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
