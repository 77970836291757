import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { API_URL } from "../../env";

const levelOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Advanced", label: "Advanced" },
];

export default function CourseModal({ open, setOpen, setRefresh, courseData }) {
  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
    Level: "",
    Duration: "",
    PrerequisiteCourses: "",
    Certificate: false,
    thumbnail: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (courseData) {
      setFormData({
        Title: courseData.Title || "",
        Description: courseData.Description || "",
        Level: courseData.Level || "",
        Duration: courseData.Duration || "",
        PrerequisiteCourses: courseData.PrerequisiteCourses || "",
        Certificate: courseData.Certificate || false,
        thumbnail: null,
      });
    }
  }, [courseData]);

  const handleClose = () => {
    setOpen(false);
    setFormData({
      Title: "",
      Description: "",
      Level: "",
      Duration: "",
      PrerequisiteCourses: "",
      Certificate: false,
      thumbnail: null,
    });
    setError("");
  };

  const handleInputChange = (field) => (event) => {
    const value =
      field === "Certificate" ? event.target.checked : event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFormData((prev) => ({
        ...prev,
        thumbnail: event.target.files[0],
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // Validate required fields
      const requiredFields = ["Title", "Description", "Level", "Duration"];
      const missingFields = requiredFields.filter((field) => !formData[field]);

      if (missingFields.length > 0) {
        setError(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
        return;
      }

      // Create FormData object for file upload
      const submitData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "thumbnail") {
          if (formData[key]) {
            submitData.append(key, formData[key]);
          }
        } else {
          submitData.append(key, formData[key]);
        }
      });

      const url = courseData
        ? `${API_URL}course/${courseData.CourseId}`
        : `${API_URL}course/`;

      const method = courseData ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: submitData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setRefresh((prev) => !prev);
      handleClose();
    } catch (err) {
      setError(
        courseData
          ? "Failed to update course."
          : "Failed to create course. Please try again."
      );
      console.error(
        courseData ? "Error updating course:" : "Error creating course:",
        err
      );
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const renderTextField = (
    label,
    field,
    select = false,
    options = [],
    type = "text"
  ) => (
    <Grid item xs={6}>
      <label className="label">{label}*</label>
      <TextField
        fullWidth
        type={type}
        value={formData[field]}
        onChange={handleInputChange(field)}
        variant="outlined"
        sx={textFieldStyles}
        select={select}
      >
        {select &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="courses-box" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {courseData ? "Edit Course" : "Create New Course"}
            </Typography>
            <Box noValidate autoComplete="off">
              <Grid container spacing={2}>
                {renderTextField("Title", "Title")}
                <Grid item xs={12}>
                  <label
                    className="label"
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      display: "block",
                    }}
                  >
                    Description*
                  </label>
                  <TextField
                    style={{ background: "white" }}
                    fullWidth
                    value={formData.Description}
                    onChange={handleInputChange("Description")}
                    variant="outlined"
                    multiline
                    rows={2}
                  />
                </Grid>
                {renderTextField("Level", "Level", true, levelOptions)}
                {renderTextField("Duration", "Duration", false, [], "number")}
                {renderTextField("Prerequisite Courses", "PrerequisiteCourses")}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.Certificate}
                        onChange={handleInputChange("Certificate")}
                        color="primary"
                      />
                    }
                    label="Certificate"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="label">Thumbnail{!courseData && "*"}</label>
                  <TextField
                    fullWidth
                    type="file"
                    onChange={handleFileChange}
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      "& .MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        accept: "image/*",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit}
                style={{
                  opacity: loading ? 0.7 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                {loading ? "Submitting..." : courseData ? "Update" : "Submit"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
