// QuestionCreateModal.jsx
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL } from "../../env";

const questionTypes = [
  { value: "multiple_choice", label: "Multiple Choice" },
  { value: "true_false", label: "True/False" },
  { value: "short_answer", label: "Short Answer" },
];

const QuestionCreateModal = ({
  open,
  handleClose,
  quizId,
  questionData,
  refreshQuestions,
}) => {
  const [formData, setFormData] = useState({
    question: "",
    question_type: "multiple_choice",
    options: {},
    correct_answer: {},
    score: 1,
    explanation: "",
    case_id: "",
  });

  const [options, setOptions] = useState([
    { key: "A", value: "" },
    { key: "B", value: "" },
    { key: "C", value: "" },
    { key: "D", value: "" },
  ]);

  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [correctTrueFalse, setCorrectTrueFalse] = useState("true");
  const [shortAnswerResponse, setShortAnswerResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [questionImage, setQuestionImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    if (questionData) {
      setFormData({
        question: questionData.question || "",
        question_type: questionData.question_type || "multiple_choice",
        score: questionData.score || 1,
        explanation: questionData.explanation || "",
        case_id: questionData.case_id || "",
      });

      // Handle options for multiple choice
      if (
        questionData.question_type === "multiple_choice" &&
        questionData.options
      ) {
        try {
          const parsedOptions =
            typeof questionData.options === "string"
              ? JSON.parse(questionData.options)
              : questionData.options;

          const optionsArray = Object.entries(parsedOptions).map(
            ([key, value]) => ({
              key,
              value,
            })
          );

          setOptions(
            optionsArray.length > 0
              ? optionsArray
              : [
                  { key: "A", value: "" },
                  { key: "B", value: "" },
                  { key: "C", value: "" },
                  { key: "D", value: "" },
                ]
          );

          // Set correct answers
          const parsedCorrectAnswers =
            typeof questionData.correct_answer === "string"
              ? JSON.parse(questionData.correct_answer)
              : questionData.correct_answer;

          setCorrectAnswers(
            Array.isArray(parsedCorrectAnswers) ? parsedCorrectAnswers : []
          );
        } catch (error) {
          console.error("Error parsing options:", error);
          setOptions([
            { key: "A", value: "" },
            { key: "B", value: "" },
            { key: "C", value: "" },
            { key: "D", value: "" },
          ]);
          setCorrectAnswers([]);
        }
      }

      // Handle true/false answer
      if (
        questionData.question_type === "true_false" &&
        questionData.correct_answer
      ) {
        try {
          const parsedAnswer =
            typeof questionData.correct_answer === "string"
              ? JSON.parse(questionData.correct_answer)
              : questionData.correct_answer;

          // If it's an array, take the first element
          if (Array.isArray(parsedAnswer) && parsedAnswer.length > 0) {
            setCorrectTrueFalse(parsedAnswer[0]);
          } else {
            // Legacy support for non-array format
            const tfAnswer = String(parsedAnswer);
            setCorrectTrueFalse(tfAnswer === "true" ? "true" : "false");
          }
        } catch (error) {
          console.error("Error parsing true/false answer:", error);
          setCorrectTrueFalse("true");
        }
      }

      // Handle short answer
      if (
        questionData.question_type === "short_answer" &&
        questionData.correct_answer
      ) {
        try {
          const parsedAnswer =
            typeof questionData.correct_answer === "string"
              ? JSON.parse(questionData.correct_answer)
              : questionData.correct_answer;

          if (Array.isArray(parsedAnswer) && parsedAnswer.length > 0) {
            setShortAnswerResponse(parsedAnswer[0]);
          } else {
            // Legacy support for non-array format
            setShortAnswerResponse(String(parsedAnswer) || "");
          }
        } catch (error) {
          console.error("Error parsing short answer:", error);
          setShortAnswerResponse("");
        }
      }

      // Set preview image if available
      if (questionData.image_url) {
        setPreviewImage(questionData.image_url);
      }
    }
  }, [questionData]);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Reset options and correct answers when question type changes
    if (field === "question_type") {
      if (value === "multiple_choice") {
        setOptions([
          { key: "A", value: "" },
          { key: "B", value: "" },
          { key: "C", value: "" },
          { key: "D", value: "" },
        ]);
        setCorrectAnswers([]);
        setShortAnswerResponse("");
      } else if (value === "true_false") {
        setCorrectTrueFalse("true");
        setCorrectAnswers([]);
        setShortAnswerResponse("");
      } else if (value === "short_answer") {
        setCorrectAnswers([]);
        setShortAnswerResponse("");
      }
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index].value = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const nextKey = String.fromCharCode(65 + options.length); // A, B, C, ...
    setOptions([...options, { key: nextKey, value: "" }]);
  };

  const handleRemoveOption = (index) => {
    if (options.length <= 2) {
      setError("At least two options are required");
      return;
    }

    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);

    // Update correct answers if a correct option was removed
    const removedKey = options[index].key;
    if (correctAnswers.includes(removedKey)) {
      setCorrectAnswers(correctAnswers.filter((key) => key !== removedKey));
    }
  };

  const handleCorrectAnswerChange = (optionKey) => {
    if (correctAnswers.includes(optionKey)) {
      setCorrectAnswers(correctAnswers.filter((key) => key !== optionKey));
    } else {
      setCorrectAnswers([...correctAnswers, optionKey]);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setQuestionImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleClearImage = () => {
    setQuestionImage(null);
    setPreviewImage("");
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // Validate required fields
      if (!formData.question || !formData.question_type) {
        setError("Question and question type are required");
        setLoading(false);
        return;
      }

      // Validate options for multiple choice
      if (formData.question_type === "multiple_choice") {
        if (options.some((option) => !option.value)) {
          setError("All options must have values");
          setLoading(false);
          return;
        }

        if (correctAnswers.length === 0) {
          setError("Please select at least one correct answer");
          setLoading(false);
          return;
        }
      }

      // Validate short answer
      if (
        formData.question_type === "short_answer" &&
        !shortAnswerResponse.trim()
      ) {
        setError(
          "Please provide a correct answer for the short answer question"
        );
        setLoading(false);
        return;
      }

      // Create FormData for multipart/form-data submission
      const submitData = new FormData();
      submitData.append("question", formData.question);
      submitData.append("question_type", formData.question_type);
      submitData.append("score", formData.score);

      if (formData.explanation) {
        submitData.append("explanation", formData.explanation);
      }

      if (formData.case_id) {
        submitData.append("case_id", formData.case_id);
      }

      // Handle options based on question type
      if (formData.question_type === "multiple_choice") {
        const optionsObj = {};
        options.forEach((option) => {
          optionsObj[option.key] = option.value;
        });

        submitData.append("options", JSON.stringify(optionsObj));
        submitData.append("correct_answer", JSON.stringify(correctAnswers)); // Already an array
      } else if (formData.question_type === "true_false") {
        submitData.append(
          "options",
          JSON.stringify({ true: "True", false: "False" })
        );
        // Convert to array format
        submitData.append("correct_answer", JSON.stringify([correctTrueFalse]));
      } else if (formData.question_type === "short_answer") {
        // Short answer doesn't use options in the same way
        submitData.append("options", "{}");
        // For short answer, send the response as an array
        submitData.append(
          "correct_answer",
          JSON.stringify([shortAnswerResponse])
        );
      }

      // Add image if selected
      if (questionImage) {
        submitData.append("question_image", questionImage);
      }

      // Determine URL and method based on edit or create
      const url = questionData
        ? `${API_URL}quizz/${quizId}/questions/${questionData.question_id}`
        : `${API_URL}quizz/${quizId}/questions`;

      const method = questionData ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Don't set Content-Type - let browser set it with boundary for FormData
        },
        body: submitData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || response.statusText}`);
      }

      // Close the modal and refresh questions
      handleClose();
      refreshQuestions();
    } catch (err) {
      console.error("Error handling question:", err);
      setError(
        questionData
          ? "Failed to update question. Please try again."
          : "Failed to create question. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="question-modal-title"
        aria-describedby="question-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            className="courses-box"
            sx={{
              p: 4,
              width: "80%",
              maxWidth: "700px",
              maxHeight: "80vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="question-modal-title"
              variant="h5"
              component="h2"
            >
              {questionData ? "Edit Question" : "Create New Question"}
            </Typography>
            <Box noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <label className="label">Question Text*</label>
                  <TextField
                    fullWidth
                    value={formData.question}
                    onChange={handleInputChange("question")}
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label className="label">Question Type*</label>
                      <TextField
                        fullWidth
                        select
                        value={formData.question_type}
                        onChange={handleInputChange("question_type")}
                        variant="outlined"
                        sx={{ backgroundColor: "white" }}
                      >
                        {questionTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <label className="label">Points</label>
                      <TextField
                        fullWidth
                        type="number"
                        value={formData.score}
                        onChange={handleInputChange("score")}
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{ backgroundColor: "white" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <label className="label">Question Image (Optional)</label>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button variant="outlined" component="label" sx={{ my: 1 }}>
                      Upload Image
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Button>
                    {previewImage && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleClearImage}
                      >
                        Clear Image
                      </Button>
                    )}
                  </Box>
                  {previewImage && (
                    <Box sx={{ mt: 2, mb: 3, maxWidth: "300px" }}>
                      <img
                        src={previewImage}
                        alt="Question preview"
                        style={{ width: "100%", borderRadius: "4px" }}
                      />
                    </Box>
                  )}
                </Grid>

                {formData.question_type === "multiple_choice" && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="subtitle1">
                        Answer Options*
                      </Typography>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddOption}
                        size="small"
                      >
                        Add Option
                      </Button>
                    </Box>

                    {options.map((option, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={correctAnswers.includes(option.key)}
                              onChange={() =>
                                handleCorrectAnswerChange(option.key)
                              }
                              sx={{ color: "#2279F5" }}
                            />
                          }
                          label={`Option ${option.key}:`}
                          sx={{ width: "120px", mr: 1 }}
                        />
                        <TextField
                          fullWidth
                          value={option.value}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                          variant="outlined"
                          placeholder={`Enter option ${option.key}`}
                          sx={{ backgroundColor: "white", flex: 1 }}
                        />
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveOption(index)}
                          sx={{ ml: 1 }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <Typography variant="caption" color="text.secondary">
                      Select the radio button(s) next to the correct answer(s)
                    </Typography>
                  </Grid>
                )}

                {formData.question_type === "true_false" && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Correct Answer*</Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={correctTrueFalse}
                        onChange={(e) => setCorrectTrueFalse(e.target.value)}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

                {formData.question_type === "short_answer" && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Correct Answer*</Typography>
                    <TextField
                      fullWidth
                      value={shortAnswerResponse}
                      onChange={(e) => setShortAnswerResponse(e.target.value)}
                      variant="outlined"
                      placeholder="Enter the correct answer for this short answer question"
                      sx={{ backgroundColor: "white", mb: 2 }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      Enter the expected answer. This will be used for
                      auto-grading.
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <label className="label">Explanation (Optional)</label>
                  <TextField
                    fullWidth
                    value={formData.explanation}
                    onChange={handleInputChange("explanation")}
                    variant="outlined"
                    multiline
                    rows={2}
                    placeholder="Provide an explanation for the correct answer (optional)"
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <label className="label">Case Study ID (Optional)</label>
                  <TextField
                    fullWidth
                    value={formData.case_id}
                    onChange={handleInputChange("case_id")}
                    variant="outlined"
                    placeholder="Associate with a case study (if applicable)"
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <Typography
                      color="error"
                      sx={{ mt: 2, textAlign: "center" }}
                    >
                      {error}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>

            <div className="submit-div" style={{ marginTop: "20px" }}>
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit}
                style={{
                  opacity: loading ? 0.7 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                {loading ? "Submitting..." : questionData ? "Update" : "Submit"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default QuestionCreateModal;
