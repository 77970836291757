import React from "react";
import { Box, Typography, Drawer, Grid, Chip } from "@mui/material";
import InfoBox from "./InfoBox";

const QuizInfoDrawer = ({ open, onClose, quiz }) => {
  if (!quiz) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: "80%", sm: "50%", md: "30%" },
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 3,
            pb: 1,
            borderBottom: "2px solid #2279F5",
            display: "inline-block",
          }}
        >
          Quiz Information
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <InfoBox title="Description">
            <Typography variant="body2" sx={{ mb: 1 }}>
              {quiz?.description || "No description provided"}
            </Typography>
          </InfoBox>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InfoBox title="Passing Score" height="100%">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    color: "#2279F5",
                    fontSize: "1.2rem",
                  }}
                >
                  {quiz?.passing_score}%
                </Typography>
              </InfoBox>
            </Grid>
            <Grid item xs={6}>
              <InfoBox title="Time Limit" height="100%">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    color: "#2279F5",
                    fontSize: "1.2rem",
                  }}
                >
                  {quiz?.time_limit ? `${quiz.time_limit} min` : "None"}
                </Typography>
              </InfoBox>
            </Grid>
          </Grid>

          <InfoBox title="Quiz Settings">
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body2">Max Attempts:</Typography>
              <Chip
                label={quiz?.max_attempts || "Unlimited"}
                size="small"
                sx={{
                  bgcolor: "rgba(34, 121, 245, 0.1)",
                  color: "#2279F5",
                  fontWeight: 500,
                  fontSize: "0.7rem",
                }}
              />
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body2">Shuffle Questions:</Typography>
              <Chip
                label={quiz?.shuffle_questions ? "Yes" : "No"}
                size="small"
                sx={{
                  bgcolor: quiz?.shuffle_questions
                    ? "rgba(76, 175, 80, 0.1)"
                    : "rgba(211, 47, 47, 0.1)",
                  color: quiz?.shuffle_questions ? "#4CAF50" : "#D32F2F",
                  fontWeight: 500,
                  fontSize: "0.7rem",
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2">Show Results:</Typography>
              <Chip
                label={quiz?.show_results ? "Yes" : "No"}
                size="small"
                sx={{
                  bgcolor: quiz?.show_results
                    ? "rgba(76, 175, 80, 0.1)"
                    : "rgba(211, 47, 47, 0.1)",
                  color: quiz?.show_results ? "#4CAF50" : "#D32F2F",
                  fontWeight: 500,
                  fontSize: "0.7rem",
                }}
              />
            </Box>
          </InfoBox>
        </Box>
      </Box>
    </Drawer>
  );
};

export default QuizInfoDrawer;
