import React from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
} from "@mui/material";

const TrueFalseQuestion = ({ question, selectedOption, onChange }) => {
  // Handle option selection
  const handleOptionChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <RadioGroup value={selectedOption || ""} onChange={handleOptionChange}>
        {["true", "false"].map((option, index) => {
          const label = option === "true" ? "True" : "False";

          return (
            <Paper
              key={option}
              elevation={selectedOption === option ? 3 : 1}
              sx={{
                p: 2,
                mb: 2,
                borderRadius: "8px",
                border:
                  selectedOption === option
                    ? "2px solid #4a6fa5"
                    : "1px solid #e0e0e0",
                backgroundColor:
                  selectedOption === option
                    ? "rgba(74, 111, 165, 0.1)"
                    : "white",
                transition: "all 0.2s ease",
              }}
            >
              <FormControlLabel
                value={option}
                control={
                  <Radio
                    sx={{
                      color: "#4a6fa5",
                      "&.Mui-checked": {
                        color: "#4a6fa5",
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="body1">
                    <strong>{index === 0 ? "A" : "B"}.</strong> {label}
                  </Typography>
                }
                sx={{ width: "100%", margin: 0 }}
              />
            </Paper>
          );
        })}
      </RadioGroup>
    </Box>
  );
};

export default TrueFalseQuestion;
