import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../env";

export default function ModuleModal({
  open,
  setOpen,
  setRefresh,
  moduleData,
  selectedCourseId,
}) {
  const [formData, setFormData] = useState({
    CourseId: selectedCourseId || "",
    Title: "",
    Description: "",
    Duration: "",
    Sequence: 1,
    Status: true,
    thumbnail: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (moduleData) {
      setFormData({
        CourseId: moduleData.CourseId || selectedCourseId || "",
        Title: moduleData.Title || "",
        Description: moduleData.Description || "",
        Duration: moduleData.Duration || "",
        Sequence: moduleData.Sequence || 1,
        Status: moduleData.Status !== undefined ? moduleData.Status : true,
        thumbnail: null,
      });
    } else {
      // For new modules, always use the selectedCourseId
      setFormData((prev) => ({
        ...prev,
        CourseId: selectedCourseId || "",
      }));
    }
  }, [moduleData, selectedCourseId]);

  const handleClose = () => {
    setOpen(false);
    setFormData({
      CourseId: selectedCourseId || "",
      Title: "",
      Description: "",
      Duration: "",
      Sequence: 1,
      Status: true,
      thumbnail: null,
    });
    setError("");
  };

  const handleInputChange = (field) => (event) => {
    const value =
      field === "Status" ? event.target.checked : event.target.value;
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFormData((prev) => ({
        ...prev,
        thumbnail: event.target.files[0],
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // Validate required fields (CourseId is not in the UI but still required for backend)
      const requiredFields = ["Title", "Sequence"];
      const missingFields = requiredFields.filter(
        (field) =>
          formData[field] === "" ||
          formData[field] === null ||
          formData[field] === undefined
      );

      // Also check if CourseId is missing
      if (!formData.CourseId) {
        missingFields.push("CourseId");
      }

      if (missingFields.length > 0) {
        setError(
          `Please fill in all required fields: ${missingFields.join(", ")}`
        );
        return;
      }

      // Create FormData object for file upload
      const submitData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "thumbnail") {
          if (formData[key]) {
            submitData.append(key, formData[key]);
          }
        } else {
          submitData.append(key, formData[key]);
        }
      });

      // Use the module endpoint instead of course
      const url = moduleData
        ? `${API_URL}module/${moduleData.ModuleId}`
        : `${API_URL}module/`;

      const method = moduleData ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: submitData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setRefresh((prev) => !prev);
      handleClose();
    } catch (err) {
      setError(
        moduleData
          ? "Failed to update module."
          : "Failed to create module. Please try again."
      );
      console.error(
        moduleData ? "Error updating module:" : "Error creating module:",
        err
      );
    } finally {
      setLoading(false);
    }
  };

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const renderTextField = (label, field, required = false, type = "text") => (
    <Grid item xs={6}>
      <label className="label">
        {label}
        {required ? "*" : ""}
      </label>
      <TextField
        fullWidth
        type={type}
        value={formData[field]}
        onChange={handleInputChange(field)}
        variant="outlined"
        sx={textFieldStyles}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="courses-box" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {moduleData ? "Edit Module" : "Create New Module"}
            </Typography>
            <Box noValidate autoComplete="off">
              <Grid container spacing={2}>
                {/* Removed CourseId field from UI */}
                {renderTextField("Title", "Title", true)}
                <Grid item xs={12}>
                  <label
                    className="label"
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      display: "block",
                    }}
                  >
                    Description
                  </label>
                  <TextField
                    fullWidth
                    value={formData.Description}
                    onChange={handleInputChange("Description")}
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={{ background: "white" }}
                  />
                </Grid>
                {renderTextField("Duration", "Duration", false, "text")}
                {renderTextField("Sequence", "Sequence", true, "number")}
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.Status}
                        onChange={handleInputChange("Status")}
                        color="primary"
                      />
                    }
                    label="Status"
                    sx={{ marginTop: "20px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="label">Thumbnail</label>
                  <TextField
                    fullWidth
                    type="file"
                    onChange={handleFileChange}
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      "& .MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        accept: "image/*",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit}
                style={{
                  opacity: loading ? 0.7 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
              >
                {loading ? "Submitting..." : moduleData ? "Update" : "Submit"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
