import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Fade,
} from "@mui/material";
import {
  Close,
  CheckCircle,
  ArrowForward,
  ArrowBack,
  AccessTime,
  InfoOutlined,
} from "@mui/icons-material";
import { API_URL } from "../../env";
import QuizAttemptService from "./services/QuizAttemptService";
import EnhancedQuizTimer from "./EnhancedQuizTimer";

const StudentAnswerModal = ({ open, onClose, question, quizId, onSubmit }) => {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [attemptInfo, setAttemptInfo] = useState({
    attemptId: null,
    totalAttempts: 0,
    remainingAttempts: 3,
    timeLimit: 15, // minutes
    timeRemaining: null,
    currentQuestion: 1,
    totalQuestions: 10,
    currentScore: 0,
    totalPossibleScore: 100,
  });
  const [timerActive, setTimerActive] = useState(true);
  const [questionStartTime, setQuestionStartTime] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState(null);
  const [animateSelection, setAnimateSelection] = useState(false);

  // Time expired dialog state
  const [timeExpiredDialog, setTimeExpiredDialog] = useState({
    open: false,
    message: "",
  });

  // Error dialog state for maximum attempts or other errors
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  // Parse JSON safely
  const safelyParseJSON = (jsonString, fallback = {}) => {
    try {
      return typeof jsonString === "string"
        ? JSON.parse(jsonString)
        : jsonString;
    } catch (error) {
      console.error("JSON parsing error:", error);
      return fallback;
    }
  };

  // Create quiz attempt when modal opens
  useEffect(() => {
    if (open) {
      const createAttempt = async () => {
        try {
          setLoading(true);

          // Create quiz attempt
          const attemptData = await QuizAttemptService.createAttempt({
            quiz_id: quizId,
            start_time: new Date().toISOString(),
            status: "in_progress",
          });

          setAttemptInfo((prev) => ({
            ...prev,
            attemptId: attemptData.attempt_id,
            timeLimit: attemptData.time_limit || 15,
            totalAttempts: attemptData.max_attempts || 2,
            remainingAttempts: attemptData.remaining_attempts || 3,
          }));

          // Reset question start time
          setQuestionStartTime(new Date());
        } catch (error) {
          console.error("Error creating quiz attempt:", error);
          
          // Check if it's a maximum attempts error
          if (error.message && error.message.includes("MAX_ATTEMPTS_REACHED")) {
            // Extract the error message
            const errorMsg = error.message.replace("MAX_ATTEMPTS_REACHED: ", "");
            
            // Show error dialog
            setErrorDialog({
              open: true,
              title: "Maximum Attempts Reached",
              message: errorMsg || "You have reached the maximum number of attempts allowed for this quiz.",
            });
          } else {
            // For other errors
            setError("Failed to start quiz. Please try again.");
          }
        } finally {
          setLoading(false);
        }
      };

      createAttempt();
    }

    return () => {
      // Clean up if needed
      setTimerActive(false);
    };
  }, [open, quizId]);

  // Calculate time spent on current question in seconds
  const calculateTimeSpent = () => {
    if (!questionStartTime) return 0;
    const now = new Date();
    return Math.floor((now - questionStartTime) / 1000);
  };

  // Handle quiz time expiration
  const handleTimeExpired = async () => {
    setTimerActive(false);
    
    try {
      setSubmitting(true);
      
      // Auto-submit current answer even if not complete
      if (attemptInfo.attemptId) {
        const timeSpent = calculateTimeSpent();
        const userAnswer = question.question_type === "multiple_choice" 
          ? selectedOption 
          : question.question_type === "true_false" 
            ? answer 
            : answer;
        
        try {
          // Submit the answer
          await QuizAttemptService.submitAnswer(attemptInfo.attemptId, {
            question_id: question.question_id,
            user_answer: userAnswer || "", // Send empty string if null
            question_type: question.question_type,
            time_spent: timeSpent,
            answered_at: new Date().toISOString(),
          });
          
          // Submit the full quiz with timeExpired flag
          const resultData = await QuizAttemptService.submitQuiz(attemptInfo.attemptId, {
            timeExpired: true
          });
          
          // Show time expired dialog with success message
          setTimeExpiredDialog({
            open: true,
            message: "Time's up! Your quiz has been automatically submitted. Click below to return to the quiz list."
          });
        } catch (error) {
          console.error("Error submitting quiz:", error);
          
          // Show time expired dialog with error message
          setTimeExpiredDialog({
            open: true,
            message: `Time's up! There was an error submitting your quiz: ${error.message}. Click below to return to the quiz list.`
          });
        }
      } else {
        // No attempt ID found
        setTimeExpiredDialog({
          open: true,
          message: "Time's up! No active quiz attempt found. Click below to return to the quiz list."
        });
      }
    } catch (error) {
      console.error("Error handling time expiration:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (isAutoSubmit = false) => {
    // Validate if an answer was selected
    if (!isAutoSubmit) {
      if (question.question_type === "multiple_choice" && !selectedOption) {
        setError("Please select an answer");
        return;
      }

      if (question.question_type === "true_false" && !answer) {
        setError("Please select True or False");
        return;
      }

      if (question.question_type === "short_answer" && !answer.trim()) {
        setError("Please provide an answer");
        return;
      }
    }

    setSubmitting(true);
    setError(null);

    try {
      const userAnswer =
        question.question_type === "multiple_choice"
          ? selectedOption
          : question.question_type === "true_false"
          ? answer
          : answer;

      const timeSpent = calculateTimeSpent();

      if (attemptInfo.attemptId) {
        // Submit the answer via the API
        await QuizAttemptService.submitAnswer(attemptInfo.attemptId, {
          question_id: question.question_id,
          user_answer: userAnswer || "", // Send empty string if null
          question_type: question.question_type,
          time_spent: timeSpent,
          answered_at: new Date().toISOString(),
        });

        // For demo purpose, also submit full quiz if it's auto-submit
        if (isAutoSubmit) {
          await QuizAttemptService.submitQuiz(attemptInfo.attemptId, {
            timeExpired: true
          });
        }
      }

      // Pass answer back to parent component
      onSubmit(userAnswer);
    } catch (error) {
      console.error("Error submitting answer:", error);
      setError(`Failed to submit answer: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle error dialog close
  const handleErrorDialogClose = () => {
    setErrorDialog(prev => ({...prev, open: false}));
    onClose(); // Close the modal
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setError(null);

    // Trigger animation for selection
    setAnimateSelection(true);
    setTimeout(() => setAnimateSelection(false), 500);
  };

  const calculateProgress = () => {
    return (attemptInfo.currentQuestion / attemptInfo.totalQuestions) * 100;
  };

  // Time Expired Dialog component
  const TimeExpiredDialog = () => (
    <Dialog 
      open={timeExpiredDialog.open} 
      onClose={() => onClose()} // Close both dialog and modal
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: '#D32F2F' }}>
        Time's Up!
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(211, 47, 47, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <AccessTime sx={{ fontSize: 40, color: '#D32F2F' }} />
        </Box>
        <Typography variant="body1" textAlign="center">
          {timeExpiredDialog.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'center' }}>
        <Button 
          onClick={onClose}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px'
          }}
        >
          Return to Quiz
        </Button>
      </DialogActions>
    </Dialog>
  );
  
  // Error Dialog component
  const ErrorDialog = () => (
    <Dialog 
      open={errorDialog.open} 
      onClose={handleErrorDialogClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {errorDialog.title}
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(211, 47, 47, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <InfoOutlined sx={{ fontSize: 40, color: '#D32F2F' }} />
        </Box>
        <Typography variant="body1" textAlign="center">
          {errorDialog.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'center' }}>
        <Button 
          onClick={handleErrorDialogClose}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (!question) return null;

  return (
    <Dialog
      open={open}
      onClose={loading || submitting ? undefined : onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
          borderRadius: "12px",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
          overflow: "hidden",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "4px",
            background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
            zIndex: 1,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          position: "relative",
        }}
      >
        <Box sx={{ position: "relative", width: "100%", textAlign: "center" }}>
          <Typography
            variant="h5"
            color="#166088"
            sx={{
              fontWeight: 600,
              position: "relative",
              display: "inline-block",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-8px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "40px",
                height: "3px",
                background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
                borderRadius: "2px",
              },
            }}
          >
            Course Quiz
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            Test your knowledge on the topic
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={loading || submitting ? undefined : onClose}
          disabled={loading || submitting}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>

        {/* Timer at the top */}
        {attemptInfo.timeLimit > 0 && (
          <Box
            sx={{
              position: "absolute",
              right: 12,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <EnhancedQuizTimer
              timeLimit={attemptInfo.timeLimit}
              onTimeExpired={handleTimeExpired}
              isActive={timerActive && !loading && !submitting}
            />
          </Box>
        )}
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 6,
            }}
          >
            <CircularProgress
              size={60}
              thickness={4}
              sx={{ color: "#2279F5" }}
            />
            <Typography variant="h6" sx={{ mt: 3, color: "#166088" }}>
              Starting Quiz...
            </Typography>
          </Box>
        ) : (
          <>
            {/* Quiz Info Bar */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                p: 2,
                bgcolor: "rgba(255,255,255,0.7)",
                borderRadius: "8px",
                boxShadow: "0 2px 6px rgba(0,0,0,0.05)",
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: "#166088" }}
                >
                  <strong>Question:</strong> {attemptInfo.currentQuestion} of{" "}
                  {attemptInfo.totalQuestions}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: "#166088" }}
                >
                  <strong>Score:</strong> {attemptInfo.currentScore} /{" "}
                  {attemptInfo.totalPossibleScore} points
                </Typography>
              </Box>
            </Box>

            {/* Progress Bar */}
            <Box
              sx={{
                width: "100%",
                height: "8px",
                bgcolor: "rgba(0,0,0,0.05)",
                borderRadius: "4px",
                mb: 3,
                overflow: "hidden",
                boxShadow: "inset 0 1px 2px rgba(0,0,0,0.1)",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  bgcolor: "#4cb5ae",
                  width: `${calculateProgress()}%`,
                  background:
                    "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
                  transition: "width 0.3s ease",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                }}
              />
            </Box>

            {/* Question Content */}
            <Paper
              elevation={0}
              sx={{
                mb: 3,
                p: 3,
                bgcolor: "rgba(255,255,255,0.9)",
                borderRadius: "12px",
                boxShadow: "0 4px 16px rgba(0,0,0,0.08)",
                transition: "transform 0.2s ease, box-shadow 0.2s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 20px rgba(0,0,0,0.12)",
                },
              }}
            >
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{
                  mb: 2,
                  color: "#166088",
                  borderBottom: "2px solid rgba(34, 121, 245, 0.1)",
                  paddingBottom: "10px",
                }}
              >
                {attemptInfo.currentQuestion}. {question.question}
              </Typography>

              {question.score && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  <strong>Points for this question:</strong> {question.score}{" "}
                  points
                </Typography>
              )}

              {/* Question Image (if present) */}
              {question.image_url && (
                <Box
                  component="img"
                  src={question.image_url || "/api/placeholder/600/300"}
                  alt="Question Image"
                  sx={{
                    maxWidth: "100%",
                    borderRadius: "8px",
                    mb: 3,
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  }}
                />
              )}

              {/* Answer Options */}
              {question.question_type === "multiple_choice" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.8rem",
                  }}
                >
                  {Object.entries(safelyParseJSON(question.options, {})).map(
                    ([key, value], index) => {
                      const letters = ["A", "B", "C", "D", "E", "F"];
                      const letter =
                        index < letters.length ? letters[index] : index + 1;
                      const isSelected = selectedOption === key;

                      return (
                        <Fade
                          key={key}
                          in={true}
                          style={{ transitionDelay: `${index * 50}ms` }}
                        >
                          <Paper
                            onClick={() => handleSelectOption(key)}
                            sx={{
                              padding: "1rem",
                              bgcolor: isSelected
                                ? "rgba(225, 240, 255, 0.7)"
                                : "rgba(245, 245, 245, 0.6)",
                              border: `2px solid ${
                                isSelected
                                  ? "#4a6fa5"
                                  : "rgba(224, 224, 224, 0.7)"
                              }`,
                              borderRadius: "8px",
                              cursor: "pointer",
                              transition: "all 0.2s ease",
                              display: "flex",
                              alignItems: "center",
                              boxShadow: isSelected
                                ? "0 4px 12px rgba(74, 111, 165, 0.2)"
                                : "none",
                              transform:
                                isSelected && animateSelection
                                  ? "scale(1.02)"
                                  : "scale(1)",
                              "&:hover": {
                                bgcolor: isSelected
                                  ? "rgba(225, 240, 255, 0.7)"
                                  : "rgba(237, 247, 253, 0.7)",
                                borderColor: isSelected
                                  ? "#4a6fa5"
                                  : "rgba(197, 225, 249, 0.9)",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                              },
                            }}
                            elevation={isSelected ? 2 : 0}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                bgcolor: isSelected ? "#4a6fa5" : "white",
                                border: `2px solid ${
                                  isSelected ? "#4a6fa5" : "#ccc"
                                }`,
                                mr: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                color: isSelected ? "white" : "inherit",
                                transition: "all 0.2s ease",
                                boxShadow: isSelected
                                  ? "0 2px 5px rgba(0,0,0,0.15)"
                                  : "none",
                              }}
                            >
                              {isSelected ? (
                                <CheckCircle fontSize="small" />
                              ) : (
                                letter
                              )}
                            </Box>
                            <Typography>{value}</Typography>
                          </Paper>
                        </Fade>
                      );
                    }
                  )}
                </Box>
              )}

              {question.question_type === "true_false" && (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  {["true", "false"].map((value, index) => {
                    const labels = ["True", "False"];
                    const letters = ["A", "B"];
                    const isSelected = answer === value;

                    return (
                      <Fade
                        key={value}
                        in={true}
                        style={{ transitionDelay: `${index * 50}ms` }}
                      >
                        <Paper
                          onClick={() => {
                            setAnswer(value);
                            setError(null);
                            setAnimateSelection(true);
                            setTimeout(() => setAnimateSelection(false), 500);
                          }}
                          sx={{
                            padding: "1rem",
                            bgcolor: isSelected
                              ? "rgba(225, 240, 255, 0.7)"
                              : "rgba(245, 245, 245, 0.6)",
                            border: `2px solid ${
                              isSelected
                                ? "#4a6fa5"
                                : "rgba(224, 224, 224, 0.7)"
                            }`,
                            borderRadius: "8px",
                            cursor: "pointer",
                            transition: "all 0.2s ease",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: isSelected
                              ? "0 4px 12px rgba(74, 111, 165, 0.2)"
                              : "none",
                            transform:
                              isSelected && animateSelection
                                ? "scale(1.02)"
                                : "scale(1)",
                            "&:hover": {
                              bgcolor: isSelected
                                ? "rgba(225, 240, 255, 0.7)"
                                : "rgba(237, 247, 253, 0.7)",
                              borderColor: isSelected
                                ? "#4a6fa5"
                                : "rgba(197, 225, 249, 0.9)",
                              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
                            },
                          }}
                          elevation={isSelected ? 2 : 0}
                        >
                          <Box
                            sx={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "50%",
                              bgcolor: isSelected ? "#4a6fa5" : "white",
                              border: `2px solid ${
                                isSelected ? "#4a6fa5" : "#ccc"
                              }`,
                              mr: "12px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                              color: isSelected ? "white" : "inherit",
                              transition: "all 0.2s ease",
                              boxShadow: isSelected
                                ? "0 2px 5px rgba(0,0,0,0.15)"
                                : "none",
                            }}
                          >
                            {isSelected ? (
                              <CheckCircle fontSize="small" />
                            ) : (
                              letters[index]
                            )}
                          </Box>
                          <Typography fontWeight={isSelected ? 500 : 400}>
                            {labels[index]}
                          </Typography>
                        </Paper>
                      </Fade>
                    );
                  })}
                </Box>
              )}

              {question.question_type === "short_answer" && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                    value={answer}
                    onChange={(e) => {
                      setAnswer(e.target.value);
                      setError(null);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#e0e0e0",
                          borderWidth: "2px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#c5e1f9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#4a6fa5",
                        },
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}
                  />
                </Box>
              )}

              {/* Error Message */}
              {error && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "rgba(231, 76, 60, 0.1)",
                    color: "#e74c3c",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    borderLeft: "4px solid #e74c3c",
                  }}
                >
                  {error}
                </Box>
              )}
            </Paper>
          </>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          sx={{
            bgcolor: "rgba(224, 224, 224, 0.5)",
            color: "#666",
            fontWeight: 600,
            px: 3,
            borderRadius: "8px",
            borderColor: "transparent",
            "&:hover": {
              bgcolor: "rgba(208, 208, 208, 0.7)",
              borderColor: "rgba(0, 0, 0, 0.12)",
            },
          }}
          onClick={onClose}
          disabled={loading || submitting}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          disabled={
            loading ||
            submitting ||
            (question.question_type === "multiple_choice" && !selectedOption) ||
            (question.question_type === "true_false" && !answer) ||
            (question.question_type === "short_answer" && !answer.trim())
          }
          onClick={() => handleSubmit(false)}
          sx={{
            bgcolor: "#4a6fa5",
            background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
            color: "white",
            fontWeight: 600,
            px: 3,
            borderRadius: "8px",
            "&:hover": {
              background: "linear-gradient(90deg, #1e6ad4 0%, #00bfe8 100%)",
              boxShadow: "0 4px 12px rgba(34, 121, 245, 0.3)",
            },
            "&.Mui-disabled": {
              bgcolor: "#a0a0a0",
              opacity: 0.6,
            },
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
          }}
        >
          {submitting ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
              Submitting...
            </Box>
          ) : (
            "Next Question"
          )}
        </Button>
      </DialogActions>

      {/* Time Expired Dialog */}
      <TimeExpiredDialog />
      
      {/* Error Dialog */}
      <ErrorDialog />
    </Dialog>
  );
};

export default StudentAnswerModal;