// CoursesList.jsx
import React, { useState, useEffect } from "react";
import "./Courses.css";
import CoursesModal from "./CoursesModal";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Pagination,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { API_URL } from "../../env";
import Module from "./Module/Module";

const CoursesList = () => {
  const [openCourseModal, setOpenCourseModal] = useState(false);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const handleViewCourse = (course) => {
    setSelectedCourseId(course.CourseId);
    setOpenSelectionModal(true);
  };
  const handleMenuClick = (event, course) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCourse(course);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenCourseModal(true);
    handleMenuClose();
  };

  const handleDelete = async () => {
    if (!selectedCourse) return;

    try {
      const response = await fetch(
        `${API_URL}course/${selectedCourse.CourseId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error deleting course:", error);
    }
    handleMenuClose();
  };

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_URL}course/?page=${page}&page_size=8${
          searchTerm ? `&search=${searchTerm}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setCourses(data.courses);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [page, searchTerm, refresh]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const renderCourseCards = () => (
    <Grid style={{ padding: 20 }} container spacing={3}>
      {courses.map((course) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={2.4}
          lg={3}
          key={course.CourseId}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              boxShadow: "0px 4px 20px rgba(34, 121, 245, 0.15)",
              borderRadius: "12px",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: {
                      md: "1rem",
                      lg: "1.1rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 175,
                    },
                  }}
                >
                  {course.Title}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => handleMenuClick(e, course)}
                  sx={{ padding: 0 }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 1,
                  fontSize: {
                    md: "0.8rem",
                    lg: "0.875rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 175,
                  },
                }}
              >
                {course.Description}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    backgroundColor: "#E3F2FD",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    color: "#2279F5",
                  }}
                >
                  {course.Level}
                </Typography>
                <Typography variant="caption">
                  Duration: {course.Duration}h
                </Typography>
              </Box>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  background:
                    "linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%)",
                  borderRadius: "8px",
                }}
                onClick={() => handleViewCourse(course)}
              >
                View Course
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Grid>
  );

  return (
    <>
      {openSelectionModal ? (
        <Module
          selectedCourseId={selectedCourseId}
          openSelectionModal={openSelectionModal}
          setOpenSelectionModal={setOpenSelectionModal}
        />
      ) : (
        <div className="worklist-container">
          <div className="courses-header">
            <div className="header-title">Courses</div>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                size="small"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: "gray", mr: 1 }} />,
                }}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              <div
                className="courses-case"
                onClick={() => {
                  setSelectedCourse(null);
                  setOpenCourseModal(true);
                }}
              >
                Create Courses
              </div>
            </Box>
          </div>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {renderCourseCards()}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, value) => setPage(value)}
                  color="primary"
                />
              </Box>
            </>
          )}

          {openCourseModal && (
            <CoursesModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={openCourseModal}
              setOpen={setOpenCourseModal}
              courseData={selectedCourse}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CoursesList;
