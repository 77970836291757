import React from "react";
import { Box, Typography } from "@mui/material";

// Reusable styled component for info boxes
const InfoBox = ({ title, children, height = "auto" }) => (
  <Box
    sx={{
      p: 2,
      height,
      bgcolor: "rgba(255, 255, 255, 0.7)",
      borderRadius: "8px",
      boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
      border: "1px solid rgba(34, 121, 245, 0.1)",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography variant="subtitle2" color="#2279F5" sx={{ mb: 0.5 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

export default InfoBox;
