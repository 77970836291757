import React, { useEffect, useState } from "react";
import "./MyCaseBox.css";
import {
  Delete,
  DeleteOutline,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  Edit,
  AttachFile,
  Share,
} from "@mui/icons-material";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { API_URL } from "../../env";
import { Copy, CopyCheck, Info, QrCode } from "lucide-react";
import CreateMyCaseBoxModal from "./CreateMyCaseBoxModal";
import MyCaseBoxImages from "./MyCaseBoxImages";
import ShareCaseBoxModal from "./ShareCaseBoxModal";
import ShareSlidesModal from "./ShareCaseBoxModal";

const MyCaseBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState({});
  const [filter, setFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [caseFolder, setCaseFolder] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const formatDateToUserTimeZone = (utcDateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(utcDateString, userTimeZone);
    return format(zonedDate, "MMMM do, yyyy h:mm:ss a");
  };

  // Fetch all cases
  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URL}case_box/?page=${page}&limit=5`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCaseFolder(data.caseboxes);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching cases:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch cases. Please try again.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [refresh, page]);

  // Fetch single case details
  const fetchSingleCase = async (caseBoxId) => {
    try {
      const response = await fetch(`${API_URL}case_box/${caseBoxId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching case details:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch case details. Please try again.",
      });
      throw error;
    }
  };

  const handleShowImages = (row) => {
    setSelectedRowData(row);
    setShowImages(true);
  };

  const handleEdit = async (caseData) => {
    try {
      const detailedCase = await fetchSingleCase(caseData.CaseBoxId);
      setSelectedCase(detailedCase);
      setIsEditMode(true);
      setOpen(true);
    } catch (error) {
      console.error("Error in edit handling:", error);
    }
  };

  const handleSelectAll = (e) => {
    const newSelectedSlides = {};
    if (e.target.checked) {
      caseFolder?.forEach((slide) => {
        newSelectedSlides[slide.CaseBoxId] = true;
      });
    }
    setSelectedSlides(newSelectedSlides);
  };

  const handleSelectSlide = (CaseBoxId) => {
    setSelectedSlides((prevSelected) => ({
      ...prevSelected,
      [CaseBoxId]: !prevSelected[CaseBoxId],
    }));
  };

  const isSlideSelected = (CaseBoxId) => !!selectedSlides[CaseBoxId];
  const handleShare = (caseData) => {
    setSelectedCase(caseData);
    setShareModalOpen(true);
  };
  const isAllSelected =
    caseFolder?.length > 0 &&
    Object.keys(selectedSlides).length === caseFolder?.length;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenCreate = () => {
    setIsEditMode(false);
    setSelectedCase(null);
    setOpen(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // Implement search functionality here
  };

  const handleViewInfo = async (caseData) => {
    try {
      const detailedCase = await fetchSingleCase(caseData.CaseBoxId);
      setSelectedCase(detailedCase);
      setIsEditMode(false);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching case details:", error);
    }
  };

  // Add delete functionality for a single case
  const handleDelete = async (caseBoxId) => {
    try {
      // Ask for confirmation before deleting
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        setIsLoading(true);
        
        const response = await fetch(`${API_URL}case_box/${caseBoxId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Refresh the list after successful deletion
        setRefresh(!refresh);
        
        Swal.fire(
          'Deleted!',
          'Your case box has been deleted.',
          'success'
        );
      }
    } catch (error) {
      console.error('Error deleting case:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete case. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add batch delete functionality for selected cases
  const handleBatchDelete = async () => {
    // Get selected case IDs
    const selectedCaseIds = Object.keys(selectedSlides).filter(id => selectedSlides[id]);
    
    if (selectedCaseIds.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'No Selection',
        text: 'Please select at least one case to delete.',
      });
      return;
    }

    try {
      // Ask for confirmation before batch deleting
      const result = await Swal.fire({
        title: 'Delete multiple cases?',
        text: `You are about to delete ${selectedCaseIds.length} case(s). This action cannot be undone!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete them!'
      });

      if (result.isConfirmed) {
        setIsLoading(true);
        
        // Delete cases one by one
        const deletePromises = selectedCaseIds.map(caseBoxId => 
          fetch(`${API_URL}case_box/${caseBoxId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          })
        );
        
        const results = await Promise.allSettled(deletePromises);
        
        // Check if any deletions failed
        const failedDeletions = results.filter(result => result.status === 'rejected');
        
        if (failedDeletions.length > 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Partial Success',
            text: `${results.length - failedDeletions.length} case(s) deleted successfully, ${failedDeletions.length} failed.`,
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${results.length} case(s) deleted successfully.`,
          });
        }
        
        // Clear selections and refresh the list
        setSelectedSlides({});
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error('Error in batch deletion:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete selected cases. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (showImages) {
    return (
      <MyCaseBoxImages
        caseData={selectedRowData}
        caseBoxId={selectedRowData.CaseBoxId}
        onBack={() => setShowImages(false)}
      />
    );
  }

  return (
    <div>
      <div className="casebox-container">
        <div
          className="casebox-header"
          style={{ justifyContent: "space-between" }}
        >
          <div className="casebox-header-title">My Casebox</div>
          <div className="casebox-filter-box">
            <div className="casebox-search">
              <input
                style={{ border: "none" }}
                className="casebox-search-input"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <div>
                <Search />
              </div>
            </div>
            <div className="casebox-date-filter">
              From
              <input className="casebox-date-input" type="date" />
            </div>
            <div className="casebox-date-filter-to">
              To
              <input className="casebox-date-input" type="date" />
            </div>
            <div className="casebox-create-btn" onClick={handleOpenCreate}>
              Create Case Box
            </div>

            <div 
              className="casebox-delete-btn"
              onClick={handleBatchDelete}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                <Delete fontSize="12px" />
              </div>
              <div>Delete</div>
            </div>
          </div>
        </div>

        <div className="casebox-table-container">
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ padding: 0, borderBottom: "none" }}
                    className="casebox-checkbox-cell"
                  >
                    <Checkbox
                      className="casebox-checkbox"
                      indeterminate={
                        Object.keys(selectedSlides).length > 0 && !isAllSelected
                      }
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell className="casebox-table-head">Name</TableCell>
                  <TableCell className="casebox-table-head">
                    Total Number Of Slides
                  </TableCell>
                  <TableCell className="casebox-table-head">
                    Created At
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "end", borderBottom: "none" }}
                    className="casebox-table-head-actions"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {caseFolder?.map((row, index) => (
                  <React.Fragment key={row.CaseBoxId}>
                    <TableRow
                      className="casebox-table-row"
                      onDoubleClick={() => handleShowImages(row)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        style={{
                          borderTopLeftRadius: 12,
                          borderBottomLeftRadius: 12,
                        }}
                        className="casebox-table-cell casebox-cell-checkbox"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Checkbox
                          className="casebox-checkbox"
                          checked={isSlideSelected(row.CaseBoxId)}
                          onChange={() => handleSelectSlide(row.CaseBoxId)}
                        />
                      </TableCell>
                      <TableCell className="casebox-table-cell">
                        {row.CaseBoxName}
                      </TableCell>
                      <TableCell className="casebox-table-cell">
                        {row.TotalNumberOfSlides}
                      </TableCell>
                      <TableCell className="casebox-table-cell">
                        {formatDateToUserTimeZone(row.CreatedAt)}
                      </TableCell>
                      <TableCell
                        style={{
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          textAlign: "end",
                        }}
                        className="casebox-table-cell casebox-actions-cell"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewInfo(row);
                          }}
                        >
                          <Info className="icon-info" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(row);
                          }}
                        >
                          <Edit className="icon-edit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowImages(row);
                          }}
                        >
                          <RemoveRedEyeOutlined className="icon-view" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShare(row);
                          }}
                        >
                          <Share className="icon-share" />
                        </IconButton>
                        <IconButton 
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(row.CaseBoxId);
                          }}
                        >
                          <DeleteOutline className="icon-delete" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <div className="casebox-row-spacer"></div>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </div>

        <div className="casebox-pagination-container">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>
        {shareModalOpen && (
          <ShareSlidesModal
            shareModal={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
            caseBoxId={selectedCase?.CaseBoxId}
          />
        )}

        {open && (
          <CreateMyCaseBoxModal
            refresh={refresh}
            setRefresh={setRefresh}
            open={open}
            setOpen={setOpen}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            caseData={selectedCase}
            isInfoMode={selectedCase && !isEditMode}
          />
        )}
      </div>
    </div>
  );
};

export default MyCaseBox;