import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Grid,
  Chip,
  Alert,
  Snackbar,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { 
  ArrowBack, 
  ArrowForward, 
  Save, 
  InfoOutlined,
  AccessTime,
  CheckCircle,
  Error
} from "@mui/icons-material";
import { API_URL } from "../../env";
import QuizAttemptService from "./services/QuizAttemptService";
import EnhancedQuizTimer from "./EnhancedQuizTimer";

// Import question type components
import MultipleChoiceQuestion from "./question-types/MultipleChoiceQuestion";
import TrueFalseQuestion from "./question-types/TrueFalseQuestion";
import ShortAnswerQuestion from "./question-types/ShortAnswerQuestion";

// Import the QuizSubmissionDialog component
import QuizSubmissionDialog from "./QuizSubmissionDialog";

const QuizTakingPage = () => {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  // State for quiz data
  const [quiz, setQuiz] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});

  // State for current answer
  const [currentAnswer, setCurrentAnswer] = useState(null);

  // State for quiz attempt
  const [attemptData, setAttemptData] = useState(null);
  const [questionStartTime, setQuestionStartTime] = useState(null);

  // State for quiz progress
  const [quizStartTime, setQuizStartTime] = useState(null);
  const [timerActive, setTimerActive] = useState(true);

  // UI state
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Submission dialog state
  const [submissionDialog, setSubmissionDialog] = useState({
    open: false,
    title: "",
    message: "",
    status: "success",
    resultData: null
  });

  // Error dialog state
  const [errorDialog, setErrorDialog] = useState({
    open: false,
    title: "",
    message: "",
    redirectPath: "",
  });

  // Confirmation dialog state
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: "",
    message: "",
    action: null
  });

  // Debug log for tracking answers
  useEffect(() => {
    console.log("User answers updated:", userAnswers);
  }, [userAnswers]);

  // Fetch quiz data on component mount
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        setLoading(true);

        // Fetch quiz details
        const quizResponse = await fetch(`${API_URL}quizz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!quizResponse.ok) {
          throw new Error(`Failed to fetch quiz: ${quizResponse.status}`);
        }

        const quizData = await quizResponse.json();
        setQuiz(quizData);

        // Set up quiz start time
        const now = new Date();
        setQuizStartTime(now);
        setQuestionStartTime(now);

        // Create quiz attempt
        try {
          const attemptResponse = await QuizAttemptService.createAttempt({
            quiz_id: quizId,
            start_time: now.toISOString(),
            status: "in_progress",
          });

          setAttemptData(attemptResponse);
        } catch (attemptError) {
          console.error("Error creating quiz attempt:", attemptError);
          
          // Check if it's a maximum attempts error
          if (attemptError.message && attemptError.message.includes("MAX_ATTEMPTS_REACHED")) {
            // Extract the error message
            const errorMsg = attemptError.message.replace("MAX_ATTEMPTS_REACHED: ", "");
            
            // Show error dialog for maximum attempts reached
            setErrorDialog({
              open: true,
              title: "Maximum Attempts Reached",
              message: errorMsg,
              redirectPath: "/home", // Redirect to home/quiz list page
            });
            
            setLoading(false);
            return; // Stop further execution
          }
          
          // Continue with other types of errors and show as notification
          setNotification({
            open: true,
            message: `Error starting quiz: ${attemptError.message}`,
            severity: "error",
          });
        }

        // Fetch total questions count
        const questionsCountResponse = await fetch(
          `${API_URL}quizz/${quizId}/questions?page=1&page_size=1`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!questionsCountResponse.ok) {
          throw new Error(
            `Failed to fetch questions: ${questionsCountResponse.status}`
          );
        }

        const questionsData = await questionsCountResponse.json();
        setTotalQuestions(questionsData.total_questions || 0);

        // Fetch first question
        await fetchQuestion(1);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [quizId, navigate]);

  // Function to fetch a specific question
  const fetchQuestion = async (questionNumber) => {
    try {
      // If we're changing questions, submit the current answer first
      if (currentQuestion && currentAnswer !== null && attemptData) {
        try {
          await submitCurrentAnswer();
        } catch (submitError) {
          console.error("Error submitting current answer:", submitError);
          // Continue even if submission fails
        }
      }

      setLoading(true);

      const response = await fetch(
        `${API_URL}quizz/${quizId}/questions?page=${questionNumber}&page_size=1`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch question: ${response.status}`);
      }

      const data = await response.json();

      if (data.questions && data.questions.length > 0) {
        // Reset question start time for the new question
        setQuestionStartTime(new Date());
        setCurrentQuestion(data.questions[0]);
        setQuestionNumber(questionNumber);

        // Set current answer if it exists in userAnswers
        const questionId = data.questions[0].question_id;
        setCurrentAnswer(userAnswers[questionId] || null);
      } else {
        throw new Error("No questions found");
      }
    } catch (error) {
      console.error("Error fetching question:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Calculate time spent on current question in seconds
  const calculateTimeSpent = () => {
    if (!questionStartTime) return 0;
    const now = new Date();
    return Math.floor((now - questionStartTime) / 1000);
  };

  // Submit the current answer to the API
  const submitCurrentAnswer = async () => {
    if (!currentQuestion || currentAnswer === null || !attemptData) return;

    const timeSpent = calculateTimeSpent();

    try {
      await QuizAttemptService.submitAnswer(attemptData.attempt_id, {
        question_id: currentQuestion.question_id,
        user_answer: currentAnswer,
        question_type: currentQuestion.question_type,
        time_spent: timeSpent,
        answered_at: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error submitting answer:", error);
      throw error;
    }
  };

  // Handle answer change for any question type
  const handleAnswerChange = (answer) => {
    if (!currentQuestion) return;

    let processedAnswer;

    switch (currentQuestion.question_type) {
      case "multiple_choice":
        processedAnswer = answer;
        break;
      case "true_false":
        processedAnswer = answer === "true" ? true : false;
        break;
      case "short_answer":
        processedAnswer = answer;
        break;
      default:
        processedAnswer = answer;
    }

    // Update current answer state
    setCurrentAnswer(processedAnswer);

    // Also save to userAnswers immediately
    saveAnswer(currentQuestion.question_id, processedAnswer);
  };

  // Handle navigation to previous question
  const handlePrevious = () => {
    if (questionNumber > 1) {
      // Save current answer before navigating
      if (currentQuestion && currentAnswer !== null) {
        saveAnswer(currentQuestion.question_id, currentAnswer);
      }
      fetchQuestion(questionNumber - 1);
    }
  };

  // Handle navigation to next question
  const handleNext = () => {
    // Save current answer
    if (currentQuestion && currentAnswer !== null) {
      saveAnswer(currentQuestion.question_id, currentAnswer);
    }

    if (questionNumber < totalQuestions) {
      fetchQuestion(questionNumber + 1);
    } else {
      // If this is the last question, show submit confirmation
      showSubmitConfirmation();
    }
  };

  // Save answer to state
  const saveAnswer = (questionId, answer) => {
    setUserAnswers((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  // Show confirmation before submitting quiz
  const showSubmitConfirmation = () => {
    // Make sure current answer is saved before checking
    if (currentQuestion && currentAnswer !== null) {
      saveAnswer(currentQuestion.question_id, currentAnswer);
    }

    // Check if all questions are answered
    const answeredCount = Object.keys(userAnswers).length;

    if (answeredCount < totalQuestions) {
      const unansweredCount = totalQuestions - answeredCount;
      
      // Show confirmation dialog
      setConfirmDialog({
        open: true,
        title: "Unanswered Questions",
        message: `You have ${unansweredCount} unanswered question(s). Do you want to review them before submitting?`,
        action: submitQuiz
      });
    } else {
      submitQuiz();
    }
  };

const submitQuiz = async () => {
  try {
    setSubmitting(true);

    // Submit the current answer if it exists
    if (currentQuestion && currentAnswer !== null && attemptData) {
      try {
        await submitCurrentAnswer();
      } catch (submitError) {
        console.error("Error submitting final answer:", submitError);
        // Continue even if this fails
      }
    }

    if (!attemptData) {
      throw new Error("No active quiz attempt found");
    }

    // Submit the quiz attempt
    const resultData = await QuizAttemptService.submitQuiz(
      attemptData.attempt_id
    );

    console.log("Quiz submission result:", resultData);

    // Stop the timer
    setTimerActive(false);

    // Pass through the original API response fields and add any additional fields needed
    const formattedResults = {
      ...resultData,
      // Add any fields that aren't in the API response but are needed by the results page
      timeTaken: `${Math.floor(resultData.time_spent / 60)}m ${resultData.time_spent % 60}s`,
      totalQuestions: resultData.correct_answers + resultData.incorrect_answers,
      percentageScore: resultData.score, // Keep the original score as percentageScore
      dateCompleted: resultData.end_time
    };

    // Show success dialog
    setSubmissionDialog({
      open: true,
      title: "Quiz Submitted",
      message: "Your quiz has been successfully submitted.",
      status: "success",
      resultData: formattedResults
    });
  } catch (error) {
    console.error("Error submitting quiz:", error);
    setError(error.message);
    
    // Show error dialog
    setSubmissionDialog({
      open: true,
      title: "Submission Error",
      message: `Error submitting quiz: ${error.message}`,
      status: "error",
      resultData: null
    });
  } finally {
    setSubmitting(false);
  }
};

// Make the same change to the handleTimeExpired function as well
  
  const handleTimeExpired = async () => {
    // Pause the timer
    setTimerActive(false);
    
    // Show notification
    setNotification({
      open: true,
      message: "Time's up! Submitting quiz...",
      severity: "warning",
    });
  
    // Submit the quiz automatically
    try {
      setSubmitting(true);
  
      // Submit the current answer if it exists
      if (currentQuestion && currentAnswer !== null && attemptData) {
        try {
          await submitCurrentAnswer();
        } catch (submitError) {
          console.error("Error submitting final answer:", submitError);
          // Continue even if this fails
        }
      }
  
      if (!attemptData) {
        throw new Error("No active quiz attempt found");
      }
  
      // Submit the quiz attempt with timeExpired flag
      const resultData = await QuizAttemptService.submitQuiz(
        attemptData.attempt_id,
        { timeExpired: true }
      );
  
      console.log("Time expired submission result:", resultData);
  
      // Format the results data for the results page using the API response values
      const formattedResults = {
        score: resultData.score,
        totalScore: resultData.total_score,
        percentageScore: resultData.score, // API already returns percentage
        is_passed: resultData.is_passed,
        timeTaken: `${Math.floor(resultData.time_spent / 60)}m ${resultData.time_spent % 60}s`,
        totalQuestions: resultData.correct_answers + resultData.incorrect_answers,
        correctAnswers: resultData.correct_answers,
        incorrectAnswers: resultData.incorrect_answers,
        attemptsRemaining: resultData.attempts_remaining,
        totalAttempts: resultData.total_attempts,
        dateCompleted: resultData.end_time
      };
  
      // Show the submission dialog
      setSubmissionDialog({
        open: true,
        title: "Time's Up!",
        message: "Your time for this quiz has expired. Your answers have been automatically submitted.",
        status: "timeout",
        resultData: formattedResults
      });
    } catch (error) {
      console.error("Error submitting quiz:", error);
      setError(error.message);
      
      // Show error dialog
      setSubmissionDialog({
        open: true,
        title: "Submission Error",
        message: `There was an error submitting your quiz: ${error.message}. Please try again or contact support.`,
        status: "error",
        resultData: null
      });
    } finally {
      setSubmitting(false);
    }
  };
  // Handle dialog close
  const handleErrorDialogClose = () => {
    setErrorDialog(prev => ({...prev, open: false}));
    if (errorDialog.redirectPath) {
      navigate(errorDialog.redirectPath);
    }
  };

  // Handle confirmation dialog actions
  const handleConfirmDialogAction = () => {
    setConfirmDialog(prev => ({...prev, open: false}));
    if (confirmDialog.action) {
      confirmDialog.action();
    }
  };

  // Handle confirmation dialog cancel
  const handleConfirmDialogCancel = () => {
    setConfirmDialog(prev => ({...prev, open: false}));
  };

  // Handle submission dialog close
  const handleSubmissionDialogClose = () => {
    setSubmissionDialog(prev => ({...prev, open: false}));
    
    // If we have result data, navigate to the results page
    if (submissionDialog.resultData) {
      navigate(`/quiz-result/${quizId}`, { 
        state: { results: submissionDialog.resultData } 
      });
    } else {
      // Otherwise, navigate back to the quiz list
      navigate("/home");
    }
  };

// Handle view score action
const handleViewScore = () => {
  if (submissionDialog.resultData) {
    navigate(`/quiz-result/${quizId}`, { 
      state: { results: submissionDialog.resultData } 
    });
  }
};
  // Calculate progress percentage
  const calculateProgress = () => {
    return (questionNumber / totalQuestions) * 100;
  };

  // Render question based on type
  const renderQuestion = () => {
    if (!currentQuestion) return null;

    switch (currentQuestion.question_type) {
      case "multiple_choice":
        return (
          <MultipleChoiceQuestion
            question={currentQuestion}
            selectedOption={currentAnswer}
            onChange={handleAnswerChange}
          />
        );
      case "true_false":
        return (
          <TrueFalseQuestion
            question={currentQuestion}
            selectedOption={currentAnswer ? "true" : "false"}
            onChange={handleAnswerChange}
          />
        );
      case "short_answer":
        return (
          <ShortAnswerQuestion
            question={currentQuestion}
            answer={currentAnswer}
            onChange={handleAnswerChange}
          />
        );
      default:
        return (
          <Box
            sx={{ p: 2, bgcolor: "rgba(255, 0, 0, 0.1)", borderRadius: "4px" }}
          >
            <Typography>
              Unsupported question type: {currentQuestion.question_type}
            </Typography>
          </Box>
        );
    }
  };

  // Error dialog component
  const ErrorDialog = () => (
    <Dialog 
      open={errorDialog.open} 
      onClose={handleErrorDialogClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {errorDialog.title}
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(211, 47, 47, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <InfoOutlined sx={{ fontSize: 40, color: '#D32F2F' }} />
        </Box>
        <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
          {errorDialog.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'center' }}>
        <Button 
          variant="contained"
          onClick={handleErrorDialogClose}
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px',
            color: 'white',
            fontWeight: 600
          }}
        >
          Return to Quiz List
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Confirmation dialog component
  const ConfirmDialog = () => (
    <Dialog
      open={confirmDialog.open}
      onClose={handleConfirmDialogCancel}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {confirmDialog.title}
      </DialogTitle>
      <DialogContent sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}>
        <Box 
          sx={{ 
            bgcolor: 'rgba(255, 152, 0, 0.1)', 
            borderRadius: '50%',
            width: 80,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}
        >
          <InfoOutlined sx={{ fontSize: 40, color: '#FF9800' }} />
        </Box>
        <Typography variant="body1" textAlign="center">
          {confirmDialog.message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px 24px', justifyContent: 'space-between' }}>
        <Button 
          onClick={handleConfirmDialogCancel}
          variant="outlined"
          sx={{
            borderRadius: '8px',
            borderColor: '#FF9800',
            color: '#FF9800',
            '&:hover': {
              borderColor: '#FF9800',
              backgroundColor: 'rgba(255, 152, 0, 0.05)',
            }
          }}
        >
          Review Questions
        </Button>
        <Button 
          onClick={handleConfirmDialogAction}
          variant="contained" 
          sx={{
            borderRadius: '8px',
            background: 'linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '8px 24px'
          }}
        >
          Submit Anyway
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (loading && !currentQuestion && !errorDialog.open) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          gap: 2,
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
        }}
      >
        <CircularProgress
          size={60}
          thickness={4}
          sx={{ color: theme.palette.primary.main }}
        />
        <Typography variant="h6" color="primary.main" fontWeight="medium">
          Loading Quiz...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: "1000px",
        mx: "auto",
        minHeight: "calc(100vh - 100px)",
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4eff9 100%)",
      }}
    >
      {/* Error Dialog for Maximum Attempts */}
      <ErrorDialog />

      {/* Confirmation Dialog */}
      <ConfirmDialog />

      {/* Quiz Header */}
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mb: 3,
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "4px",
            background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
          },
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "#166088",
                position: "relative",
                display: "inline-block",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-4px",
                  left: 0,
                  width: "40px",
                  height: "3px",
                  background:
                    "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
                },
              }}
            >
              {quiz?.title}
            </Typography>
            {quiz?.description && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                {quiz.description.length > 80
                  ? `${quiz.description.substring(0, 80)}...`
                  : quiz.description}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {quiz?.time_limit && (
              <EnhancedQuizTimer
                timeLimit={quiz.time_limit}
                onTimeExpired={handleTimeExpired}
                isActive={timerActive}
              />
            )}
          </Grid>
        </Grid>

        {/* Progress bar */}
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography variant="body2" fontWeight="medium">
              Question {questionNumber} of {totalQuestions}
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {Math.round(calculateProgress())}% Complete
            </Typography>
          </Box>
          <Box
            sx={{
              height: "8px",
              bgcolor: "rgba(0,0,0,0.05)",
              borderRadius: "4px",
              overflow: "hidden",
              boxShadow: "inset 0 1px 2px rgba(0,0,0,0.1)",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: `${calculateProgress()}%`,
                background: "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)",
                transition: "width 0.3s ease",
                borderRadius: "4px",
                boxShadow: "0 1px 3px rgba(34, 121, 245, 0.3)",
              }}
            />
          </Box>
        </Box>
      </Paper>

      {/* Question Content */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: "12px",
          minHeight: "300px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(135deg, #ffffff 0%, #f9fbff 100%)",
          transition: "transform 0.2s ease, box-shadow 0.2s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 6px 25px rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 6 }}>
            <CircularProgress />
          </Box>
        ) : currentQuestion ? (
          <>
            {/* Question number and points */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#166088" }}
              >
                Question {questionNumber}
              </Typography>
              <Chip
                label={`${currentQuestion.score} points`}
                color="primary"
                sx={{
                  bgcolor: "rgba(34, 121, 245, 0.1)",
                  color: "#2279F5",
                  fontWeight: "bold",
                  border: "none",
                }}
                size="small"
              />
            </Box>

            {/* Question text */}
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                p: 2,
                bgcolor: "rgba(34, 121, 245, 0.05)",
                borderRadius: "8px",
                borderLeft: "3px solid #2279F5",
              }}
            >
              {currentQuestion.question}
            </Typography>

            {/* Question image if available */}
            {currentQuestion.image_url && (
              <Box
                component="img"
                src={currentQuestion.image_url}
                alt="Question image"
                sx={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  display: "block",
                  mx: "auto",
                  mb: 3,
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              />
            )}

            {/* Question content based on type */}
            {renderQuestion()}
          </>
        ) : (
          <Typography color="error">Failed to load question</Typography>
        )}
      </Paper>

      {/* Navigation buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={handlePrevious}
          disabled={questionNumber <= 1 || loading || submitting}
          sx={{
            borderRadius: "8px",
            borderColor: "#2279F5",
            color: "#2279F5",
            "&:hover": {
              borderColor: "#166088",
              bgcolor: "rgba(34, 121, 245, 0.05)",
            },
            transition: "all 0.2s ease",
            px: 3,
          }}
        >
          Previous
        </Button>

        <Button
          variant="contained"
          endIcon={
            questionNumber < totalQuestions ? <ArrowForward /> : <Save />
          }
          onClick={handleNext}
          disabled={loading || submitting || currentAnswer === null}
          sx={{
            borderRadius: "8px",
            background:
              questionNumber < totalQuestions
                ? "linear-gradient(90deg, #2279F5 0%, #00D2FE 100%)"
                : "linear-gradient(90deg, #28a745 0%, #20c997 100%)",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              background:
                questionNumber < totalQuestions
                  ? "linear-gradient(90deg, #1e6ad4 0%, #00bfe8 100%)"
                  : "linear-gradient(90deg, #218838 0%, #1eb888 100%)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
            },
            transition: "all 0.2s ease",
            px: 3,
          }}
        >
          {submitting
            ? "Submitting..."
            : questionNumber < totalQuestions
            ? "Next Question"
            : "Submit Quiz"}
        </Button>
      </Box>

      {/* Notification/Alert component */}{/* Notification/Alert component */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={notification.severity}
          onClose={() => setNotification({ ...notification, open: false })}
          sx={{
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            fontWeight:
              notification.severity === "warning" ||
              notification.severity === "error"
                ? "bold"
                : "normal",
            "& .MuiAlert-icon": {
              fontSize: "1.5rem",
            },
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

{/* Quiz Submission Dialog */}
<QuizSubmissionDialog
  open={submissionDialog.open}
  onClose={handleSubmissionDialogClose}
  title={submissionDialog.title}
  message={submissionDialog.message}
  status={submissionDialog.status}
  buttonText="Return to Quiz List"
  showScoreButton={submissionDialog.resultData !== null}
  onViewScore={handleViewScore}
/>
    </Box>
  );
};

export default QuizTakingPage;