// Module.jsx
import React, { useState, useEffect } from "react";
import "../Courses.css";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Pagination,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { API_URL } from "../../../env";
import ModuleModal from "./ModuleModal";
import ModalContents from "./../ModuleContents/ModuleContents";
import { ArrowBack } from "@mui/icons-material";
import ModuleContentsPage from "../ModuleContents/ModuleDetailView";

const Module = ({
  setOpenSelectionModal,
  openSelectionModal,
  selectedCourseId,
}) => {
  const [openCourseModal, setOpenCourseModal] = useState(false);
  const [openModalContents, setOpenModalContents] = useState(false);

  const [modules, setModules] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(null); // New state for module ID
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleViewModuleContents = (module) => {
    setSelectedModuleId(module.ModuleId); // Store the module ID
    setOpenModalContents(true);
  };

  const handleMenuClick = (event, module) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setOpenCourseModal(true);
    handleMenuClose();
  };

  const handleDelete = async () => {
    if (!selectedModule) return;

    try {
      const response = await fetch(
        `${API_URL}module/${selectedModule.ModuleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error deleting module:", error);
    }
    handleMenuClose();
  };

  const fetchModules = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_URL}module/${selectedCourseId}/modules?page=${page}&page_size=8${
          searchTerm ? `&search=${searchTerm}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setModules(data.modules);
      setTotalPages(data.total_pages);
    } catch (error) {
      console.error("Error fetching modules:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCourseId) {
      fetchModules();
    }
  }, [page, searchTerm, refresh, selectedCourseId]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const renderModuleCards = () => {
    if (modules.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            width: "100%",
            padding: 3,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No modules available for this course. Create a new module to get
            started.
          </Typography>
        </Box>
      );
    }

    return (
      <Grid style={{ padding: 20 }} container spacing={3}>
        {modules.map((module) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={2.4}
            lg={3}
            key={module.ModuleId}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 4px 20px rgba(34, 121, 245, 0.15)",
                borderRadius: "12px",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: {
                        md: "1rem",
                        lg: "1.25rem",
                      },
                    }}
                  >
                    {module.Title}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuClick(e, module)}
                    sx={{ padding: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mb: 1,
                    fontSize: {
                      md: "0.8rem",
                      lg: "0.875rem",
                    },
                  }}
                >
                  {module.Description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      backgroundColor: "#E3F2FD",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      color: "#2279F5",
                    }}
                  >
                    Sequence: {module.Sequence}
                  </Typography>
                  <Typography variant="caption">
                    Duration: {module.Duration}h
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    background:
                      "linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%)",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleViewModuleContents(module)}
                >
                  View Module
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Grid>
    );
  };

  return (
    <>
      {openModalContents ? (
        <>
          <ModuleContentsPage
            selectedCourseId={selectedCourseId}
            selectedModuleId={selectedModuleId} // Pass module ID to ModalContents
            openModalContents={openModalContents}
            setOpenModalContents={setOpenModalContents}
          />
        </>
      ) : (
        <div className="worklist-container">
          <div className="courses-header">
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setOpenSelectionModal(!openSelectionModal)}
            >
              <ArrowBack />
              <div style={{ marginLeft: 20 }} className="header-title">
                Module
              </div>
            </div>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                size="small"
                placeholder="Search modules..."
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ color: "gray", mr: 1 }} />,
                }}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              <div
                className="courses-case"
                onClick={() => {
                  setSelectedModule(null);
                  setOpenCourseModal(true);
                }}
              >
                Create Module
              </div>
            </Box>
          </div>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {renderModuleCards()}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, value) => setPage(value)}
                  color="primary"
                />
              </Box>
            </>
          )}

          {openCourseModal && (
            <ModuleModal
              selectedCourseId={selectedCourseId}
              refresh={refresh}
              setRefresh={setRefresh}
              open={openCourseModal}
              setOpen={setOpenCourseModal}
              moduleData={selectedModule}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Module;
