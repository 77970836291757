import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const ShortAnswerQuestion = ({ question, answer, onChange }) => {
  // Handle text input change
  const handleTextChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        Enter your answer in the text field below:
      </Typography>

      <TextField
        fullWidth
        multiline
        rows={4}
        placeholder="Type your answer here..."
        value={answer || ""}
        onChange={handleTextChange}
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "& fieldset": {
              borderColor: "#e0e0e0",
              borderWidth: "1px",
            },
            "&:hover fieldset": {
              borderColor: "#4a6fa5",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4a6fa5",
              borderWidth: "2px",
            },
          },
        }}
      />
    </Box>
  );
};

export default ShortAnswerQuestion;
